import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Container from 'components/Container';
import SectionTitle from 'components/UI/SectionTitle';
import Link from 'components/UI/Link';
import MediaAsset from 'components/UI/MediaAsset';

import ContactsForm from './components/ContactsForm';
import BackgroundMap from './components/BackgroundMap';

import {
  ContentContainer,
  InfoBlock,
  ContactBlock,
  ContactsContainer,
  LinksBlock,
  ContactHeroWrapper,
} from './styles';

import getRichText from '../../utils/functions/getRichText';

const ContactHeroModule = ({ component }) => {
  const {
    address,
    businessDetails,
    businessInformation,
    form,
    socialLinks,
    title,
  } = component;

  const isMobile = useMediaQuery('(max-width:960px)');

  return (
    <ContactHeroWrapper>
      <Container
        cap="1256"
        topSpacingDesktop="185"
        bottomSpacingDesktop="46"
        topSpacingMobile="110"
        bottomSpacingMobile="34"
        style={{ height: isMobile ? 'initial' : '100vh' }}
      >
        <ContentContainer>
          <InfoBlock>
            <SectionTitle text={title} lightdarkMode={true} />
            <ContactsContainer>
              {businessInformation && (
                <ContactBlock>{getRichText(businessInformation)}</ContactBlock>
              )}
              {businessDetails && (
                <ContactBlock>
                  {socialLinks && (
                    <LinksBlock>
                      {socialLinks.map((link) => (
                        <Link
                          target="_blank"
                          to={link.externalLink}
                          key={link.externalLink}
                        >
                          <MediaAsset image={link.icon} />
                        </Link>
                      ))}
                    </LinksBlock>
                  )}
                  {getRichText(businessDetails)}
                </ContactBlock>
              )}
            </ContactsContainer>
          </InfoBlock>
          {form && <ContactsForm form={form} />}
        </ContentContainer>
      </Container>
      <BackgroundMap address={address} />
    </ContactHeroWrapper>
  );
};

export default ContactHeroModule;
