import React, { useState, useEffect, useRef } from 'react';
import {
  StyledInput,
  InputBlock,
  Error,
  StyledSelect,
  StyledOption,
  StyledTextArea,
} from './styles';

const Input = ({
  error,
  placeholder,
  type,
  selectOptions,
  state,
  setState,
  id,
  name,
}) => {
  const inputRef = useRef();

  const [inputValue, setInputValue] = useState('');
  const [valid, setValid] = useState(true);

  useEffect(() => {
    setState((prev) => {
      return {
        ...prev,
        [name]: {
          value: '',
          valid: true,
          type: type,
        },
      };
    });
  }, []);

  useEffect(() => {
    for (const key in state) {
      if (key === name) {
        setValid(state[key].valid);
      }
    }
  }, [state]);

  const handleChange = (e) => {
    setValid(true);
    setState({
      ...state,
      [name]: {
        value: e.target.value,
        valid: true,
        type: type,
      },
    });
    setInputValue(e.target.value);
  };

  return (
    <InputBlock>
      {selectOptions && type === 'select' ? (
        <StyledSelect
          defaultValue="DEFAULT"
          onChange={handleChange}
          error={!valid}
          inputValue={inputValue}
        >
          <StyledOption value="DEFAULT" disabled selected>
            {placeholder}
          </StyledOption>
          {selectOptions.map((option, i) => (
            <StyledOption value={option} key={`${option}_${i}`}>
              {option}
            </StyledOption>
          ))}
        </StyledSelect>
      ) : type === 'textarea' ? (
        <StyledTextArea
          placeholder={placeholder}
          onChange={handleChange}
          error={!valid}
          id={id}
          name={name}
        />
      ) : (
        <StyledInput
          id={id}
          name={name}
          placeholder={placeholder}
          type={type}
          error={!valid}
          ref={inputRef}
          onChange={handleChange}
        />
      )}
      {!valid && error && <Error>{error}</Error>}
    </InputBlock>
  );
};

export default Input;
