import React from 'react';

import Container from 'components/Container';
import AnimatedSplitTitle from 'components/AnimatedSplitTitle';
import SectionTitle from 'components/UI/SectionTitle';
import MediaAsset from 'components/UI/MediaAsset';
import Typography from 'components/UI/Typography';
import AluproteckIcons from 'components/AluproteckIcons/AluproteckIcons';

import {
  ContentContainer,
  TextContainer,
  ImagesContainer,
  TitleBlock,
  ImageWrapper,
  LogoWrapper,
  HeroWrapper,
} from './styles';

import theme from '../../utils/styles/theme';

const SmallImagesHero = ({ component }) => {
  const { images, overline, title } = component;

  return (
    <HeroWrapper>
      <Container
        cap="1256"
        topSpacingDesktop="300"
        bottomSpacingDesktop="100"
        topSpacingMobile="245"
        bottomSpacingMobile="70"
      >
        <ContentContainer>
          <TextContainer>
            {overline && <SectionTitle text={overline} displaced small />}
            {title && (
              <TitleBlock>
                <Typography
                  style={{
                    fontFamily: theme.fontFamily.recursive,
                    color: theme.colors.primary,
                  }}
                  elementTheme="h1"
                >
                  <AnimatedSplitTitle>{title}</AnimatedSplitTitle>
                </Typography>
              </TitleBlock>
            )}
          </TextContainer>
          <ImagesContainer>
            {images.map((image, i) => (
              <ImageWrapper key={`${image.title}_${i}`}>
                <MediaAsset image={image} />
              </ImageWrapper>
            ))}
          </ImagesContainer>
        </ContentContainer>
        <LogoWrapper>
          <AluproteckIcons type="light-logo" />
        </LogoWrapper>
      </Container>
    </HeroWrapper>
  );
};

export default SmallImagesHero;
