import styled from 'styled-components';
import Typography from 'components/UI/Typography';

export const ContentAndImageWrapper = styled.div`
  position: relative;
`;

export const Content = styled.div`
  width: 58.4%;
  padding: 70px 167px 75px 53px;
  background-color: ${(props) =>
    props.darkMode ? props.theme.colors.light : props.theme.colors.primary};
  display: flex;
  flex-direction: column;
  z-index: 2;

  @media (max-width: ${(props) => props.theme.breakpoints.l}) {
    padding: 40px 82px 40px 45px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    width: 100%;
    margin-top: 140px;
    padding: 40px 23px 28px 23px;
  }
`;

export const ContentHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;

  @media (max-width: ${(props) => props.theme.breakpoints.s}) {
    flex-direction: column;
    align-items: center;
  }

  .Typography {
    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
      width: calc(100% - 132px);
    }
  }
`;

export const IconWrapper = styled.div`
  width: 90px;
  height: auto;
  margin-right: 42px;

  @media (max-width: ${(props) => props.theme.breakpoints.s}) {
    width: 60px;
    height: 60px;
    margin-right: 0;
    margin-bottom: 27px;
  }
`;

export const Title = styled(Typography)`
  font-family: ${(props) => props.theme.fontFamily.recursive};
  color: ${(props) =>
    props.darkMode ? props.theme.colors.primary : props.theme.colors.white};

  @media (max-width: ${(props) => props.theme.breakpoints.s}) {
    text-align: center;
  }
`;

export const ImageWrapper = styled.div`
  width: 49.4%;
  position: absolute;
  left: calc(58.4% - 7.2%);
  bottom: 100px;
  z-index: 3;

  @media (max-width: ${(props) => props.theme.breakpoints.l}) {
    left: calc(65% - 12%);
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    z-index: 1;
    left: calc(-100vw + 100%);
    width: 120vw;
    height: 70%;
    top: 45px;
    overflow: hidden;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.s}) {
    width: 115vw;
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: 37px;
  @media (max-width: ${(props) => props.theme.breakpoints.s}) {
    display: flex;
    justify-content: center;
  }
`;

export const BodyTextWrapper = styled.div`
  color: ${(props) =>
    props.darkMode ? props.theme.colors.primary : props.theme.colors.white};
  & > p {
    font-size: 18px !important;
    &:not(&:first-child) {
      margin-top: 25px;
    }
  }
`;
