import React from 'react';
import PropTypes from 'prop-types';
import Container from 'components/Container';
import SectionTitle from 'components/UI/SectionTitle';
import ContentAndButtonCard from 'components/ContentAndButtonCard';

import {
  DropdownsWrapper,
  InformationContainer,
  DropdownsContainer,
  Dropdowns,
} from './styles';

const DropdownsModule = ({ component }) => {
  const { title, dropdowns } = component;

  return (
    <DropdownsWrapper>
      <Container
        style={{ position: 'initial' }}
        containerBackgroundColor="transparent"
        topSpacingMobile="119"
        bottomSpacingMobile="52"
        topSpacingDesktop="172"
        bottomSpacingDesktop="126"
        cap="1256"
      >
        <InformationContainer>
          {title && <SectionTitle lightdarkMode={false} text={title} />}
        </InformationContainer>
        {dropdowns && (
          <DropdownsContainer>
            <Dropdowns>
              {dropdowns.map((dropdown, inx) => (
                <ContentAndButtonCard
                  key={dropdown?.contentful_id}
                  title={dropdown?.title}
                  text={dropdown?.body.body}
                  head={false}
                  collapsed={true}
                  collapsedState={inx === 0}
                  triggerOpen={dropdown?.triggerOpen}
                  triggerClose={dropdown?.triggerClose}
                />
              ))}
            </Dropdowns>
          </DropdownsContainer>
        )}
      </Container>
    </DropdownsWrapper>
  );
};

DropdownsModule.propTypes = {
  component: PropTypes.object.isRequired,
};

DropdownsModule.defaultProps = {
  component: {},
};

export default DropdownsModule;
