import styled from 'styled-components';
import Typography from 'components/UI/Typography';

export const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const TextContainer = styled.div`
  flex: 1.1;
  display: flex;
  flex-direction: column;
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    margin-bottom: 70px;
  }
`;

export const TitleBlock = styled.div`
  margin-top: 42px;
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    margin-top: 25px;
  }
`;

export const ImagesContainer = styled.div`
  flex: 0.8;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 44px;
  row-gap: 50px;
  align-items: center;

  @media (max-width: ${(props) => props.theme.breakpoints.l}) {
    column-gap: 30px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    width: 100%;
    column-gap: 26px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.s}) {
    justify-items: center;
  }
`;

export const ImageWrapper = styled.div`
  width: 120px;
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    width: 100px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.s}) {
    width: 70px;
  }
`;

export const HeroWrapper = styled.div`
  position: relative;
`;

export const LogoWrapper = styled.div`
  position: absolute;
  height: 100%;
  right: 0;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    width: 311px;
    height: 311px;
    top: 90px;
    left: 50%;
    transform: translateX(-50%);
    & > svg {
      width: 100%;
      height: 100%;
    }
  }
`;
