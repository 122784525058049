import styled from 'styled-components';

export const StatistickContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
`;

export const StatistickBlock = styled.div`
  width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
  color: ${(props) => props.theme.colors.white};

  * {
    text-align: center;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    width: 25%;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.s}) {
    &:nth-child(odd) {
      margin-right: 180px;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.xs}) {
    width: 50%;
    margin-right: auto !important;
    margin: 0 auto 40px;
  }
`;

export const Title = styled.h3`
  font-family: ${(props) => props.theme.fontFamily.recursive};
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 12px;
`;
