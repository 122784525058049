import styled from 'styled-components';
import { m } from 'framer-motion';

export const StandardImage = styled.img`
  width: 100%;
`;

export const MediaAssetContainer = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;
`;

export const AnimatedImageContainer = styled(m.div)``;

export const Overlay = styled(m.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: ${(props) =>
    props.isLight ? props.theme.colors.light : props.theme.colors.primary};
  transform-origin: top;
`;
