import styled from 'styled-components';

export const ContainerIframe = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  pointer-events: none;

  .responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100% !important;
    height: 100% !important;
    z-index: 2;
  }
`;

export const WrapperIframe = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: ${(props) => props.theme.colors.primary};
`;

export const PlayIconWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;

  & > svg {
    width: 90px;
    height: 90px;
  }
`;
