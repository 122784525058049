import React, { useState, useEffect, useCallback, useRef } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Container from 'components/Container';
import PopupVideo from 'components/PopupVideo';
import AnimatedSplitTitle from 'components/AnimatedSplitTitle';
import AluproteckIcons from 'components/AluproteckIcons/AluproteckIcons';
import SectionTitle from 'components/UI/SectionTitle';
import MediaAsset from 'components/UI/MediaAsset';
import Typography from 'components/UI/Typography';

import {
  HeroWrapper,
  VideoContainer,
  Wrapper,
  TitleBlock,
  BodyText,
  ButtonWrapper,
  CtaButton,
  ImageWrapper,
  GradientBlock,
  VideoPopup,
  IconWrapper,
  SecondaryTitle,
  ContentContainer,
} from './styles';
import theme from '../../utils/styles/theme';
import { useDisableBodyScroll } from '../../hooks/useDisableBodyScroll';
import AnimatedSplitBody from 'components/AnimatedSplitBody';

const VideoHeroModule = ({ component }) => {
  const {
    body,
    cta,
    image,
    overline,
    secondaryTitle,
    title,
    video,
    videoYoutubeUrl,
    enableGradientOnImage,
  } = component;

  const popupBgRef = useRef();

  const isMobile = useMediaQuery('(max-width:425px)');

  const [popupVisible, setPopupVisible] = useState(false);

  useDisableBodyScroll(popupVisible);

  useEffect(() => {
    document.addEventListener('keydown', escClick, false);

    return () => {
      document.removeEventListener('keydown', escClick, false);
    };
  }, []);

  const escClick = useCallback((event) => {
    if (event.keyCode === 27) {
      hidePopup();
    }
  }, []);

  const showPopup = () => {
    setPopupVisible(true);
  };

  const hidePopup = () => {
    setPopupVisible(false);
  };

  const popubBgClick = (e) => {
    if (e.target === popupBgRef.current) {
      hidePopup();
    }
  };

  return (
    <HeroWrapper>
      {popupVisible && videoYoutubeUrl && (
        <VideoPopup onClick={popubBgClick} ref={popupBgRef}>
          <VideoContainer>
            <IconWrapper onClick={hidePopup}>
              <AluproteckIcons type="close" />
            </IconWrapper>
            <PopupVideo videoSrc={videoYoutubeUrl} />
          </VideoContainer>
        </VideoPopup>
      )}
      <Container
        topSpacingDesktop="160"
        bottomSpacingDesktop="75"
        topSpacingMobile={'90'}
        cap="1256"
        style={{ alignItems: 'flex-start' }}
      >
        <Wrapper enableGradientOnImage={enableGradientOnImage}>
          <ContentContainer enableGradientOnImage={enableGradientOnImage}>
            {overline && <SectionTitle text={overline} displaced small />}
            {title && (
              <TitleBlock>
                <Typography
                  style={{
                    fontFamily: theme.fontFamily.recursive,
                    color: theme.colors.primary,
                  }}
                  elementTheme="h1"
                >
                  <AnimatedSplitTitle>{title}</AnimatedSplitTitle>
                </Typography>
              </TitleBlock>
            )}
            {secondaryTitle && (
              <SecondaryTitle elementTheme="h2">
                <AnimatedSplitTitle>{secondaryTitle}</AnimatedSplitTitle>
              </SecondaryTitle>
            )}
            {body?.body && (
              <BodyText elementTheme="small">
                <AnimatedSplitBody>{body.body}</AnimatedSplitBody>
              </BodyText>
            )}
            {cta && image && (
              <ButtonWrapper>
                <CtaButton onClick={showPopup}>
                  {!isMobile && <AluproteckIcons type="play" />}
                  {cta}
                  {isMobile && <AluproteckIcons type="right-arrow" />}
                </CtaButton>
              </ButtonWrapper>
            )}
          </ContentContainer>
          {image && !enableGradientOnImage && (
            <ImageWrapper enableGradientOnImage={enableGradientOnImage}>
              {enableGradientOnImage && <GradientBlock />}
              <MediaAsset
                slideUp={false}
                animated={true}
                isLight={true}
                image={image}
              />
            </ImageWrapper>
          )}
          {image && enableGradientOnImage && (
            <ImageWrapper enableGradientOnImage={enableGradientOnImage}>
              {enableGradientOnImage && <GradientBlock />}
              <MediaAsset
                slideUp={false}
                animated={true}
                isLight={true}
                image={image}
              />
            </ImageWrapper>
          )}
        </Wrapper>
      </Container>
    </HeroWrapper>
  );
};

export default VideoHeroModule;
