import React from 'react';
import { useWindowSize } from 'react-use';
import Container from 'components/Container';
import SectionTitle from 'components/UI/SectionTitle';
import List from './components/List';

import {
  AnodizingWrapper,
  InformationContainer,
  ListContainer,
} from './styles';

const AnodizingModule = ({ component }) => {
  const { title, steps } = component;
  const { width } = useWindowSize();

  return (
    <Container
      style={{ position: 'initial' }}
      containerBackgroundColor="transparent"
      topSpacingMobile="44"
      bottomSpacingMobile="26.17"
      topSpacingDesktop="115"
      bottomSpacingDesktop="128"
      cap="1256"
    >
      <AnodizingWrapper>
        <InformationContainer>
          {title && (
            <SectionTitle
              lightdarkMode={false}
              text={title}
              maxWidth={width >= 960 ? 503 : 289}
              animated
            />
          )}
        </InformationContainer>
        <ListContainer>
          {steps && <List list={steps} />}
        </ListContainer>
      </AnodizingWrapper>
    </Container>
  );
};

export default AnodizingModule;
