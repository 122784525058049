import React from 'react';

import Container from 'components/Container';
import MediaAsset from 'components/UI/MediaAsset';
import Button from 'components/UI/Button';
import theme from 'utils/styles/theme.js';
import {
  ContentAndImageWrapper,
  Content,
  ContentHeader,
  IconWrapper,
  Title,
  ImageWrapper,
  ButtonWrapper,
  BodyTextWrapper,
} from './styles';
import getRichText from '../../utils/functions/getRichText';
import getRoute from '../../utils/functions/getRoute.js';
import AnimatedSplitTitle from 'components/AnimatedSplitTitle';

const ContentAndImageModule = ({ component }) => {
  const { contentAndImageBody, darkMode, icon, image, internalLink, title } =
    component;

  return (
    <ContentAndImageWrapper>
      <Container
        style={{ alignItems: 'flex-start', position: 'relative' }}
        containerBackgroundColor={
          darkMode ? theme.colors.primary : theme.colors.light
        }
        topSpacingDesktop="70"
        bottomSpacingDesktop="200"
        cap="1256"
      >
        <Content darkMode={darkMode}>
          <ContentHeader>
            {icon && (
              <IconWrapper>
                <MediaAsset image={icon} />
              </IconWrapper>
            )}
            {title && (
              <Title elementTheme="h2 bold" darkMode={darkMode}>
                <AnimatedSplitTitle>{title}</AnimatedSplitTitle>
              </Title>
            )}
          </ContentHeader>
          <BodyTextWrapper darkMode={darkMode}>
            {getRichText(contentAndImageBody)}
          </BodyTextWrapper>
          {internalLink && (
            <ButtonWrapper>
              <Button
                to={getRoute(internalLink)}
                isExternal={!!internalLink.externalLink}
              >
                {internalLink.title}
              </Button>
            </ButtonWrapper>
          )}
        </Content>
        {image && (
          <ImageWrapper>
            <MediaAsset
              isLight={true}
              animated={true}
              aspectRatio={1.22}
              image={image}
            />
          </ImageWrapper>
        )}
      </Container>
    </ContentAndImageWrapper>
  );
};

export default ContentAndImageModule;
