import React, { useState, useContext, useEffect } from 'react';
import Generic from 'components/Generic';
import MediaAsset from 'components/UI/MediaAsset';
import {
  ArticleHeader,
  ArticleContainer,
  ArticleSidebar,
  CopyToClipboardContainer,
  ArticleContent,
  ArticleTitle,
  ArticleCategories,
  ArticleAuthorBody,
  CopyToClipboardToolTip,
} from './styles';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import Typography from 'components/UI/Typography';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import LocaleContext from 'context/LocaleProvider';
import Container from 'components/Container';
import { LazyMotion, domAnimation } from 'framer-motion';

const facebookIcon = (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.8687 1.03125H2.13125C1.52374 1.03125 1.03125 1.52374 1.03125 2.13125V19.8687C1.03125 20.4763 1.52374 20.9688 2.13125 20.9688H11.6875V13.2584H9.09219V10.2403H11.6875V8.01969C11.6875 5.445 13.2619 4.0425 15.5581 4.0425C16.3319 4.04081 17.1053 4.07982 17.875 4.15938V6.85094H16.2938C15.0425 6.85094 14.7984 7.44219 14.7984 8.31531V10.2369H17.7891L17.4006 13.255H14.7812V20.9688H19.8687C20.4763 20.9688 20.9688 20.4763 20.9688 19.8687V2.13125C20.9688 1.52374 20.4763 1.03125 19.8687 1.03125Z"
      fill="#222121"
    />
  </svg>
);

const copyPasteIcon = (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.4328 2.41076C14.3429 2.46301 13.2948 2.93845 12.4846 3.74826L9.30635 6.92482C9.80556 6.42325 11.6587 6.76808 12.1082 7.21739L14.0319 5.29474C14.4579 4.86894 14.9911 4.60771 15.5373 4.5842C15.9085 4.56591 16.4338 4.64428 16.8964 5.10666C17.3277 5.53769 17.4192 6.03925 17.4192 6.38146C17.4192 6.95355 17.1578 7.52303 16.7083 7.96973L13.3628 11.3344C12.5212 12.1755 11.2405 12.2435 10.4982 11.5016C10.0748 11.0784 9.37692 11.0758 8.95089 11.5016C8.52486 11.9274 8.52486 12.6222 8.95089 13.048C9.71408 13.8108 10.7177 14.1975 11.7527 14.1975C12.8714 14.1975 14.0136 13.7403 14.8891 12.86L18.2556 9.51621C19.1102 8.66461 19.5938 7.52564 19.5938 6.38146C19.5938 5.31825 19.1913 4.30729 18.4437 3.56018C17.644 2.76081 16.5697 2.35852 15.4328 2.41076ZM10.2473 7.80254C9.12861 7.80254 7.96814 8.26231 7.08995 9.14004L3.74445 12.4838C2.88978 13.3354 2.40625 14.4743 2.40625 15.6185C2.40625 16.6817 2.80876 17.6927 3.55627 18.4398C4.35605 19.2392 5.43027 19.6415 6.56721 19.5892C7.65711 19.537 8.7052 19.0615 9.51543 18.2517L12.6937 15.0752C12.1918 15.5767 10.3414 15.2319 9.8918 14.7826L7.96814 16.7053C7.54211 17.1311 7.00892 17.3897 6.46267 17.4158C6.09153 17.4341 5.56618 17.3557 5.10356 16.8933C4.6723 16.4623 4.58082 15.9581 4.58082 15.6185C4.58082 15.0464 4.84219 14.477 5.29174 14.0303L8.63724 10.6656C9.47884 9.82446 10.7595 9.75915 11.5018 10.4984C11.9279 10.9242 12.6257 10.9242 13.0491 10.4984C13.4751 10.0726 13.4751 9.37776 13.0491 8.95195C12.2859 8.18916 11.2797 7.80254 10.2473 7.80254Z"
      fill="#222121"
    />
  </svg>
);

const twitterIcon = (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.03855 19.7433C15.0808 19.7433 19.4815 13.0741 19.4815 7.30035C19.4815 7.11273 19.4815 6.9251 19.473 6.73748C20.3258 6.12343 21.0678 5.34735 21.6562 4.46892C20.8716 4.81859 20.0273 5.04885 19.1404 5.15972C20.0444 4.62243 20.7352 3.76106 21.0678 2.73765C20.2235 3.24083 19.2854 3.59902 18.2875 3.79518C17.4859 2.94234 16.3516 2.41357 15.0979 2.41357C12.6844 2.41357 10.7228 4.37511 10.7228 6.78865C10.7228 7.12979 10.7655 7.46239 10.8337 7.78647C7.20059 7.60738 3.97685 5.85905 1.81917 3.21524C1.44392 3.8634 1.2307 4.6139 1.2307 5.41558C1.2307 6.93363 2.00679 8.27259 3.17518 9.05721C2.4588 9.03162 1.78505 8.83547 1.19659 8.51139C1.19659 8.52845 1.19659 8.5455 1.19659 8.57109C1.19659 10.6861 2.70612 12.46 4.70177 12.8609C4.33505 12.9632 3.95127 13.0144 3.55043 13.0144C3.269 13.0144 2.99609 12.9888 2.73171 12.9376C3.28605 14.6774 4.90645 15.9396 6.81681 15.9737C5.31581 17.1507 3.43104 17.85 1.38422 17.85C1.03455 17.85 0.684886 17.8329 0.34375 17.7903C2.27117 19.0184 4.57384 19.7433 7.03855 19.7433Z"
      fill="#222121"
    />
  </svg>
);

const Article = (props) => {
  const [copied, setCopied] = useState('');
  const { selectedLocale } = useContext(LocaleContext);
  const { image, title, tags, date, body, location, author } = props;

  const isEnglish = selectedLocale?.includes('en');

  const variants = {
    initial: { opacity: 0, y: 10, x: '-50%' },
    animate: { opacity: 1, y: 0, x: '-50%' },
  };

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied('');
      }, 3000);
    }
  }, [copied]);

  console.log(image)

  return (
    <ArticleContainer>
      {image && <MediaAsset image={image} />}
      <Container>
        <ArticleHeader>
          <ArticleCategories elementTheme="h4">
            {tags.map((tag, i) => (i > 0 ? `, ${tag}` : tag))}
          </ArticleCategories>
          {title && <ArticleTitle as="h1">{title}</ArticleTitle>}
          {date && (
            <ArticleAuthorBody elementTheme="small">
              {date} <br />{' '}
              {isEnglish && author
                ? `By ${author}`
                : !isEnglish && author
                ? `par ${author}`
                : ''}
            </ArticleAuthorBody>
          )}
        </ArticleHeader>
        {body && (
          <ArticleContent>
            <Generic body={body} />
          </ArticleContent>
        )}
        <ArticleSidebar>
          <div>
            <Typography style={{ marginRight: 10 }} elementTheme="small">
              {isEnglish ? 'Share:' : 'Partagez:'}
            </Typography>
            <FacebookShareButton url={location?.href}>
              {facebookIcon}
            </FacebookShareButton>
            <TwitterShareButton url={location?.href}>
              {twitterIcon}
            </TwitterShareButton>
            <CopyToClipboardContainer>
              <CopyToClipboard
                onCopy={(text) => {
                  setCopied(true);
                }}
                text={location?.href}
              >
                {copyPasteIcon}
              </CopyToClipboard>
              {copied && (
                <LazyMotion features={domAnimation}>
                  <CopyToClipboardToolTip
                    initial="initial"
                    animate="animate"
                    variants={variants}
                  >
                    {isEnglish ? 'Copied Link' : 'Lien copié'}
                  </CopyToClipboardToolTip>
                </LazyMotion>
              )}
            </CopyToClipboardContainer>
          </div>
        </ArticleSidebar>
      </Container>
    </ArticleContainer>
  );
};

export default Article;
