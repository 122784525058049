import React from 'react';
import PropTypes from 'prop-types';
import getRichText from '../../../utils/functions/getRichText';

import { TooltipWrapper, TooltipIcon, TooltipBody } from './styles';

const AnodizingTooltip = ({ popupBody, isShown }) => {
  const text = getRichText(popupBody);

  // {
  //   condition: isShown,
  //   isOverwritten: true,
  // }

  return (
    <>
      <TooltipIcon isShown={isShown} />
      <TooltipWrapper isShown={isShown}>
        <TooltipBody>{text}</TooltipBody>
      </TooltipWrapper>
    </>
  );
};

AnodizingTooltip.prototype = {
  popupBody: PropTypes.object.isRequired,
  isShown: PropTypes.bool.isRequired,
};

AnodizingTooltip.defaultProps = {
  popupBody: {},
  isShown: false,
};

export default AnodizingTooltip;
