import React from 'react';
import PropTypes from 'prop-types';
import MediaAsset from 'components/UI/MediaAsset';

import { ImageContainer } from './styles';

const Image = ({ image, active }) => {
  return (
    <ImageContainer>
      <MediaAsset
        condition={active}
        isOverwritten={true}
        slideUp={false}
        animated
        isLight
        image={image}
      />
    </ImageContainer>
  );
};

Image.prototype = {
  image: PropTypes.object.isRequired,
};

Image.defaultProps = {
  image: {},
};

export default Image;
