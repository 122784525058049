import styled from 'styled-components';
import { h3 } from 'components/UI/Typography/styles';
import Typography from 'components/UI/Typography';
import { m } from 'framer-motion';

export const ArticleContainer = styled.article`
  margin-top: 93px;
  position: relative;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    margin-top: 88px;
  }

  .Container {
    padding-bottom: 30px;
  }
`;

export const ArticleHeader = styled.div`
  width: 100%;
`;

export const ArticleTitle = styled(Typography)`
  ${h3};
  font-weight: 700;
  max-width: 540px;
  line-height: 1.1;
`;

export const ArticleCategories = styled(Typography)`
  font-weight: 700 !important;
  margin-bottom: 30px;
  opacity: 0.8;
  font-family: ${(props) => props.theme.fontFamily.roboto_mono} !important;
  text-transform: capitalize;
`;

export const ArticleAuthorBody = styled(Typography)`
  max-width: 175px;
  display: block;
  margin-top: 30px;
  opacity: 0.8;
`;

export const ArticleContent = styled.div`
  position: relative;

  > div {
    margin-top: 70px;
  }

  .Container {
    @media (max-width: ${(props) => props.theme.breakpoints.l}) {
      max-width: 100%;
      width: 100%;
    }

    @media (min-width: ${(props) => props.theme.breakpoints.l}) {
      padding-top: 10px;
    }
  }
`;

export const ArticleSidebar = styled.aside`
  margin-left: auto;
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 0;
  text-align: right;
  width: 200px;
  margin-top: 275px;
  height: 100%;

  svg {
    path {
      transition: all 0.2s ease-in-out;
    }

    :hover {
      path {
        fill: ${(props) => props.theme.colors.secondary};
      }
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.l}) {
    margin-top: 255px;
    height: initial;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    text-align: left;
    right: initial;
    left: 0;
    margin-top: 215px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.s}) {
    margin-top: 235px;
  }

  > div {
    display: flex;
    justify-content: space-between;

    @media (min-width: ${(props) => props.theme.breakpoints.l}) {
      margin-bottom: 120px;
      position: -webkit-sticky;
      position: sticky;
      top: 200px;
    }
  }
`;

export const CopyToClipboardContainer = styled.div`
  cursor: pointer;
  position: relative;
`;

export const CopyToClipboardToolTip = styled(m.div)`
  position: absolute;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
`;
