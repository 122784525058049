import styled from 'styled-components';
import Typography from '../UI/Typography';

export const HeaderBlock = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  margin-bottom: 100px;
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    margin-bottom: 46px;
  }
`;

export const TableContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  position: relative;

  & .ImageWrapper .media-asset,
  & .ImageWrapper .media-asset .AnimatedImageContainer {
    height: 100%;
  }
`;

export const ImageWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;

  & .gatsby-image-wrapper {
    width: 70%;
    height: 100%;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    width: 100%;
    /* left: -180px; */

    height: 223px;
    & .gatsby-image-wrapper {
      width: 100%;
    }
  }
/* 
  @media (max-width: ${(props) => props.theme.breakpoints.s}) {
    width: 120vw;
    left: -80px;
  } */

  /* @media (max-width: ${(props) => props.theme.breakpoints.s}) {
    width: 100vw;
    right: -20px;
    left: initial;
  } */

  @media (max-width: 500px) {
    width: 100vw;
    right: -20px;
    left: initial;
  }
`;

export const Table = styled.div`
  z-index: 2;
  width: 65%;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
`;

export const Cell = styled.div`
  background-color: ${(props) =>
    props.even ? '#eeeeee' : props.theme.colors.primary};
  background-color: ${(props) =>
    props.rowEven && props.even && props.theme.colors.white};
  color: ${(props) =>
    props.even ? props.theme.colors.primary : props.theme.colors.white};
  padding: 28px 0;
  display: flex;
  justify-content: center;

  & > svg {
    width: 40px;
    height: 40px;
  }
`;

export const HeaderCell = styled.div`
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.white};
  padding: 28px 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &:nth-child(odd) {
    background-color: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.primary};
  }
`;

export const RowTitle = styled.div`
  background-color: #eeeeee;
  padding: 28px 0 28px 60px;
  display: flex;
  align-items: center;

  &:nth-child(odd) {
    background-color: ${(props) => props.theme.colors.white};
  }
`;

export const TableMobile = styled.div`
  margin-top: 175px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.white};
  z-index: 2;
`;

export const TableMobileRow = styled.div`
  height: 102px;
  padding: 0 25px;
  background-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.primary};
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:nth-child(even) {
    background-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.white};
  }
`;

export const MobileRowText = styled(Typography)`
  font-weight: ${(props) => (props.header ? 400 : 700)};
  font-family: ${(props) => props.theme.fontFamily.recursive};
`;
