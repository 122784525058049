import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useWindowSize } from 'react-use';

import {
  Card,
  CardTitle,
  CardTextWrap,
  CardText,
  CollapseButton,
} from './styles';
import AnimatedSplitBody from 'components/AnimatedSplitBody';

const ContentAndButtonCard = ({
  title,
  text,
  head,
  collapsed,
  collapsedState,
  triggerOpen,
  triggerClose,
  children,
}) => {
  const { width } = useWindowSize();
  const [isActive, setIsActive] = useState(collapsedState);
  const [height, setHeight] = useState(0);
  const cardRef = useRef();
  const contentRef = useRef();
  const contentHeight = contentRef?.current?.clientHeight;

  const actText = width < 960 ? triggerClose : '-';
  const defText = width < 960 ? triggerOpen : '+';

  const ToggleActive = () => {
    setIsActive(!isActive);
  };

  useEffect(() => {
    isActive ? setHeight(contentHeight) : setHeight(0);
  }, [isActive, contentHeight]);

  return (
    <Card
      onClick={ToggleActive}
      className="card"
      collapsed={collapsed}
      isActive={isActive}
      ref={cardRef}
    >
      {title ? (
        <CardTitle
          className="card-title"
          elementTheme={head ? 'h3 bold' : 'p semi-bold'}
          head={head}
        >
          {title}
        </CardTitle>
      ) : (
        <div className="card-title" />
      )}
      {text ? (
        <CardTextWrap
          className="card-text"
          collapsed={collapsed}
          height={height}
        >
          <CardText collapsed={collapsed} ref={contentRef}>
            {text}
          </CardText>
        </CardTextWrap>
      ) : (
        <div className="card-text" />
      )}
      {collapsed ? (
        <CollapseButton className="card-button">
          {isActive ? actText : defText}
        </CollapseButton>
      ) : (
        <>{children}</>
      )}
    </Card>
  );
};

ContentAndButtonCard.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  head: PropTypes.bool,
  collapsed: PropTypes.bool,
  collapsedState: PropTypes.bool,
  triggerOpen: PropTypes.string,
  triggerClose: PropTypes.string,
  children: PropTypes.node,
};

ContentAndButtonCard.defaultProps = {
  head: false,
  collapsed: false,
  collapsedState: false,
};

export default ContentAndButtonCard;
