import styled from 'styled-components';
import Typography from 'components/UI/Typography';

export const Card = styled.div`
  padding: 40px 19px 26px;
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-row-gap: ${(props) =>
    props.collapsed && !props.isActive ? '15.5px' : '31px'};
  border: 2px solid ${(props) => props.theme.colors.primary};
  transition: all 0.35s ease;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    padding: ${(props) =>
      props.collapsed ? '37px 45px 31px' : '44px 53px 44px 55px'};
    grid-template-columns: ${(props) =>
      props.collapsed ? '1fr 42px' : '25.6993% 1fr 157px'};
    grid-column-gap: ${(props) => (props.collapsed ? '39px' : '77px')};
    grid-row-gap: ${(props) =>
      props.collapsed && props.isActive ? '36px' : '0'};
    align-items: ${(props) => !props.collapsed && 'center'};
  }

  .card-title {
    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
      grid-column: ${(props) => props.collapsed && '1/2'};
      grid-row: ${(props) => props.collapsed && '1/2'};
    }
  }

  .card-text {
    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
      grid-column: ${(props) => props.collapsed && '1/2'};
      grid-row: ${(props) => props.collapsed && '2/3'};
    }
  }

  .card-button {
    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
      grid-column: ${(props) => props.collapsed && '2/3'};
      grid-row: ${(props) => props.collapsed && '1/2'};
    }
  }
`;

export const CardTitle = styled(Typography)`
  font-family: ${(props) => props.theme.fontFamily.recursive};
  font-size: ${(props) =>
    props.head ? props.theme.fonts.h3 : props.theme.fonts.h4_l};
  line-height: 1.1;
  color: ${(props) => props.theme.colors.primary};

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    font-size: ${(props) =>
      props.head ? props.theme.fonts.h3_md : props.theme.fonts.h4_l};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    font-size: ${(props) =>
      props.head ? props.theme.fonts.h3_l : props.theme.fonts.h4_l};
  }
`;

export const CardTextWrap = styled.div`
  overflow: hidden;
  height: ${(props) => props.collapsed && props.height + 'px'};
  transition: ${(props) => props.collapsed && 'height 0.35s ease'};
`;

export const CardText = styled.div`
  font-size: ${(props) => props.theme.fonts.body};
  opacity: 0.8;
  color: ${(props) => props.theme.colors.primary};

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    max-width: ${(props) => (props.collapsed ? '786px' : '408px')};
    font-size: ${(props) =>
      props.collapsed ? props.theme.fonts.body_md : props.theme.fonts.body};
  }
`;

export const CollapseButton = styled.div`
  padding: 13px;
  width: 100%;
  height: 45px;
  font-family: ${(props) => props.theme.fontFamily.roboto_mono};
  font-size: ${(props) => props.theme.fonts.overline_big};
  line-height: 1.3;
  text-transform: uppercase;
  text-align: center;
  color: ${(props) => props.theme.colors.darkgrey};
  border: 1px solid ${(props) => props.theme.colors.primary};
  cursor: pointer;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    margin-top: -10px;
    padding: 10px;
    width: 42px;
    height: 42px;
    font-family: ${(props) => props.theme.fontFamily.recursive};
    font-size: ${(props) => props.theme.fonts.overline_big_md};
    line-height: 22px;
    color: ${(props) => props.theme.colors.primary};
    border: none;
  }
`;
