import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import PlusMinus from 'components/UI/PlusMinus';

import {
  AccordionWrapper,
  AccordionButtonTypography,
  AccordionButtonTitle,
  AccordionList,
  AccordionListItem,
  AccordionListWrapper,
} from './styles';

const AccordionClick = (ref, callback) => {
  const handleClick = (e) => {
    if (
      ref.current &&
      !ref.current.contains(e.target) &&
      e.target.classList.contains('accordion-button')
    ) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
};

const Accordion = ({ title, items, defActive }) => {
  const listRef = useRef();
  const accordionRef = useRef();
  const listHeight = listRef?.current?.clientHeight;
  const [isActive, setIsActive] = useState(defActive);
  const [height, setHeight] = useState(0);

  const ToggleActive = () => {
    if (!isActive) setIsActive(true);
  };

  useEffect(() => {
    isActive ? setHeight(listHeight) : setHeight(0);
  }, [isActive, listHeight]);

  AccordionClick(accordionRef, () => {
    setIsActive(false);
  });

  return (
    <AccordionWrapper className="accordion" ref={accordionRef}>
      {title && (
        <AccordionButtonTypography
          elementTheme="h3 semi-bold"
          isActive={isActive}
          onClick={ToggleActive}
          className={
            isActive ? 'accordion-button is-active' : 'accordion-button'
          }
        >
          <PlusMinus isActive={isActive} isActiveColor="#FFD600" />
          <AccordionButtonTitle>{title}</AccordionButtonTitle>
        </AccordionButtonTypography>
      )}
      {items && (
        <AccordionListWrapper height={height} isShown={isActive}>
          <AccordionList ref={listRef}>
            {items.map((item) => (
              <AccordionListItem key={item.toLocaleString()}>
                {item}
              </AccordionListItem>
            ))}
          </AccordionList>
        </AccordionListWrapper>
      )}
    </AccordionWrapper>
  );
};

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  defActive: PropTypes.bool.isRequired,
};

Accordion.defaultProps = {
  title: '',
  items: [],
  defActive: false,
};

export default Accordion;
