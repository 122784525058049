import styled from 'styled-components';

import Typography from 'components/UI/Typography';
import Button from 'components/UI/Button';

export const BannerWrapper = styled.div`
  position: relative;
`;

export const Title = styled(Typography)`
  font-family: ${(props) => props.theme.fontFamily.recursive};
  color: ${(props) => props.theme.colors.light};

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    margin-bottom: 28px;
  }
`;

export const BodyText = styled.p`
  font-size: 16px;
  color: ${(props) => props.theme.colors.light};
  max-width: 375px;
  opacity: 0.8;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    margin-bottom: 28px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  @media (max-width: ${(props) => props.theme.breakpoints.s}) {
    justify-content: center;
  }
`;

export const ButtonOutline = styled(Button)`
  background: none;
  border: 1px solid ${(props) => props.theme.colors.light};
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.light};

  & svg {
    margin-left: 15px;
    & > path {
      fill: ${(props) => props.theme.colors.light};
    }
  }

  &:hover {
    background: ${(props) => props.theme.colors.light};
    color: ${(props) => props.theme.colors.primary};
    & svg > path {
      fill: ${(props) => props.theme.colors.primary};
    }
  }
`;
