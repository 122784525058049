import React, { useRef, useEffect } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Container from 'components/Container';
import AluproteckIcons from 'components/AluproteckIcons/AluproteckIcons';
import AnimatedSplitTitle from 'components/AnimatedSplitTitle';
import AnimatedSplitBody from 'components/AnimatedSplitBody';
import Video from 'components/Video';
import ClientOnly from 'components/ClientOnly';


import {
  HeroWrapper,
  Title,
  Description,
  ScrollText,
  ScrollContainer,
  CaptionText,
  InformationContainer,
  HeroDecorationElement,
  InfoRow,
  ContentContainer,
  TitleBlock,
  VideoFixedContainer,
  VideoContent,
  TextSlideWrapper,
  TextSlide,
} from './styles';

gsap.registerPlugin(ScrollTrigger)

const HeroModule = (props) => {
  const { title, description, scrollText, caption, textSlides } = props;
  const isMobile = useMediaQuery('(max-width:960px)');
  const videoFixedContainer = useRef();
  const refVideoProgress = useRef();
  const iconRef = useRef(null);

  useEffect(() => {
    if (iconRef.current) {
      gsap.fromTo(
        iconRef.current,
        { scale: 2 },
        { scale: 1.08, duration: 2.5 }
      );
    }
  }, []);

  useEffect(() => {
    if(!videoFixedContainer.current) return;

    const elements = videoFixedContainer.current.querySelectorAll('.videoOpacityAnimation')
    const timeLines = [...elements].map(el=>{
      gsap.set(el, {opacity: 0});
  
      const tl = gsap.timeline( { 
        scrollTrigger: {
          trigger: el,
          start: "top 10%",
          end: "bottom 20%",
          scrub: true,
          toggleActions: "play reverse play reverse",
        }
      });
  
      tl
        .to(el, { opacity: 1, duration: 0.7 })
        .to(el, { opacity: 0, duration: 0.3 }, 0.7)

      return tl;
    })

    return () => {
      timeLines.forEach(tl=> tl.kill());
    }
  }, [videoFixedContainer.current])

  useEffect(() => {
    if (!refVideoProgress.current) return;
    if (!videoFixedContainer.current) return;

    gsap.set(refVideoProgress.current, {scaleX:0});

    const tl = gsap.timeline( { 
      scrollTrigger: {
        trigger: videoFixedContainer.current,
        start: "top top",
        end: "bottom bottom",
        scrub: true,
        toggleActions: "play reverse play reverse",
      }
    });

    tl.to(refVideoProgress.current, { scaleX:1, duration:1 })

    return(() => {
      tl.kill();
    })

  }, [refVideoProgress.current, videoFixedContainer.current])

  return (
    <HeroWrapper>
      <div style={{overflow: 'hidden', position: 'relative'}}>
        <Container
          style={{
            position: 'initial',
            height: isMobile ? '90vh' : 'auto',
          }}
          containerBackgroundColor="linear-gradient(270.22deg, #000000 0.74%, #695836 51.28%, #C9C9C9 99.81%)"
          topSpacingDesktop="256"
          bottomSpacingDesktop="109"
          cap="1256"
        >
          <HeroDecorationElement ref={iconRef}>
            <AluproteckIcons type="hero-decoration-element" />
          </HeroDecorationElement>

          <ContentContainer>
            <ClientOnly>
              <InformationContainer>
                {title && (
                  <TitleBlock>
                    <Title elementTheme="h1">
                      <AnimatedSplitTitle>{title}</AnimatedSplitTitle>
                    </Title>
                  </TitleBlock>
                )}
                {description && (
                  <Description elementTheme="body">
                    <AnimatedSplitBody isAwaitingLoading={true}>{description}</AnimatedSplitBody>
                  </Description>
                )}
              </InformationContainer>
            </ClientOnly>
            <InfoRow>
              {caption && (
                <CaptionText elementTheme="caption">{caption}</CaptionText>
              )}
              {scrollText && (
                <ScrollContainer>
                  <ScrollText elementTheme="caption">{scrollText}</ScrollText>
                  <AluproteckIcons type="scroll-down" />
                </ScrollContainer>
              )}
            </InfoRow>
          </ContentContainer>
        </Container>
      </div>
      <VideoFixedContainer ref={videoFixedContainer}>
        <Video {...props} refVideoProgress={refVideoProgress} />
        {textSlides.map((slide, index) => {
          return (
            <VideoContent
              key={slide.contentful_id}
              className={(index === 0 ? 'first' : '') + ' videoOpacityAnimation'}
            >
              <TextSlideWrapper>
                <TextSlide
                  elementTheme="h2"
                >
                  {slide.title}
                </TextSlide>
              </TextSlideWrapper>
            </VideoContent>
          );
        })}
        <div style={{height: '500px'}}></div>
      </VideoFixedContainer>
    </HeroWrapper>
  );
};

export default HeroModule;
