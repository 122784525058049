import styled from 'styled-components';

export const AnodizingWrapper = styled.div`
  width: 100%;
`;

export const InformationContainer = styled.div`
  margin-bottom: 30px;
  max-width: 230px;

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    max-width: unset;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    padding-left: 6.5%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    margin-bottom: 55px;
  }
`;

export const ListContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 53px 1fr auto;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto 1fr;
    grid-column-gap: 53px;
  }
`;

export const AnodizingDecorationElement = styled.div`
  grid-column: 1/2;
  grid-row: 4/5;
  display: flex;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    padding-left: calc(8.73% + 15px);
    grid-row: 1/2;
  }

  svg {
    width: 100%;

    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
      margin-right: -15px;
      width: calc(100% + 15px);
    }
  }
`;
