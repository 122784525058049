import React from 'react';
import PropTypes from 'prop-types';
import getRichText from '../../../utils/functions/getRichText';

import { BodyTypography } from './styles';

const AnodizingBody = ({ body }) => {
  const text = getRichText(body);

  return <BodyTypography>{text}</BodyTypography>;
};

AnodizingBody.prototype = {
  body: PropTypes.object.isRequired,
};

AnodizingBody.defaultProps = {
  body: {},
};

export default AnodizingBody;
