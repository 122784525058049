import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Container from 'components/Container';
import AluproteckIcons from 'components/AluproteckIcons/AluproteckIcons';
import {
  Title,
  BannerWrapper,
  BodyText,
  ButtonWrapper,
  ButtonOutline,
} from './styles';
import theme from '../../utils/styles/theme';
import getRoute from '../../utils/functions/getRoute';
import AnimatedSplitBody from 'components/AnimatedSplitBody';
import AnimatedSplitTitle from 'components/AnimatedSplitTitle';

const CtaBannerModule = ({ component }) => {
  const { ctaBannerBody, internalLink, title } = component;

  const isMobile = useMediaQuery('(max-width:960px)');

  const containerStyles = {
    desktop: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    mobile: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  };

  return (
    <BannerWrapper>
      <Container
        containerBackgroundColor={theme.colors.primary}
        style={isMobile ? containerStyles.mobile : containerStyles.desktop}
        topSpacingDesktop="58"
        bottomSpacingDesktop="58"
        topSpacingMobile="36"
        bottomSpacingMobile="39"
      >
        {title && (
          <Title elementTheme="h3 semi-bold">
            <AnimatedSplitTitle>{title}</AnimatedSplitTitle>
          </Title>
        )}
        {ctaBannerBody && (
          <BodyText>
            <AnimatedSplitBody>{ctaBannerBody}</AnimatedSplitBody>
          </BodyText>
        )}
        {internalLink && (
          <ButtonWrapper>
            <ButtonOutline
              to={getRoute(internalLink)}
              isExternal={!!internalLink.externalLink}
            >
              {internalLink.title}
              <AluproteckIcons type="right-arrow" />
            </ButtonOutline>
          </ButtonWrapper>
        )}
      </Container>
    </BannerWrapper>
  );
};

export default CtaBannerModule;
