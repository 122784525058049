import styled from 'styled-components';
import Slider from 'react-slick';
import Typography from 'components/UI/Typography';

export const ImageContainer = styled.div`
  grid-column: 1/2;
  grid-row: 1/3;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    grid-column: 2/3;
    grid-row: 1/3;
  }

  .gatsby-image-wrapper {
    margin: 0 -20px;

    @media (min-width: 490px) {
      margin: 0 calc(((100vw - 490px) / -2) - 20px);
    }

    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
      margin: 0;
    }
  }
`;

export const AnodizingList = styled.div`
  position: relative;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    padding: 0 0 10px calc(8.73% + 15px);
    grid-row: 2/3;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
`;

export const SliderWrapper = styled.div`
  margin-right: -20px;
  margin-bottom: 18px;
  grid-column: 1/2;
  grid-row: 2/4;

  @media (min-width: 641px) {
    margin-right: calc((100vw - 600px) / -2);
  }
`;

export const StyledSlider = styled(Slider)`
  .slick-slide {
    margin-right: 15px;
    max-width: 225px;
  }
`;

export const Anodizing = styled.div`
  padding: 21px;
  background-color: ${(props) => props.theme.colors.white};
  cursor: pointer;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    position: relative;
    margin-bottom: 10px;
    padding: 10px 15px;
    width: fit-content;
    background-color: transparent;
  }
`;

export const TitleTypography = styled(Typography)`
  margin-bottom: 27px;
  font-family: ${(props) => props.theme.fontFamily.recursive};

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    margin-bottom: 0;
    display: flex;
    align-items: center;
    opacity: ${(props) => (props.active ? '1' : '0.5')};
    transition: opacity 0.5s ease;
  }
`;

export const TitleCount = styled.span`
  margin-right: 0.75em;
  color: ${(props) => props.theme.colors.secondary};

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    margin-right: 30px;
    font-family: ${(props) => props.theme.fontFamily.roboto};
    font-size: ${(props) => props.theme.fonts.body_md} !important;
    color: inherit;
  }
`;

export const TitleText = styled.span``;

export const BodyTypography = styled(Typography)`
  width: calc(100% - 20px);
`;

export const TooltipIcon = styled.div`
  position: absolute;
  top: 50%;
  left: calc(100% + 67px);
  width: 60px;
  height: 4px;
  background-color: ${(props) => props.theme.colors.secondary};
  transform: ${(props) => (props.isShown ? 'scaleX(1)' : 'scaleX(0)')};
  transform-origin: 0 0;
  transition: ${(props) => (props.isShown ? 'transform 0.5s ease' : 'none')};
`;

export const TooltipWrapper = styled.div`
  padding: 35px 59px 35px;
  position: absolute;
  top: 50%;
  left: calc(100% + 127px);
  z-index: 1;
  width: 421px;
  background-color: ${(props) => props.theme.colors.white};
  transform: translateY(-50%);
  opacity: ${(props) => (props.isShown ? '1' : '0')};
  transition: ${(props) =>
    props.isShown ? 'opacity 0.25s ease 0.15s' : 'none'};
`;

export const TooltipBody = styled.div`
  font-size: ${(props) => props.theme.fonts.body_md};
  line-height: 1.4;
`;
