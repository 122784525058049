import React from 'react';
import PropTypes from 'prop-types';
import { useWindowSize } from 'react-use';
import getRichText from '../../utils/functions/getRichText';
import Container from 'components/Container';
import SectionTitle from 'components/UI/SectionTitle';
import ContentAndButtonCard from 'components/ContentAndButtonCard';

import {
  DocumentsWrapper,
  InformationContainer,
  DocumentsContainer,
  Documents,
  DownloadButton,
} from './styles';

const DocumentsModule = ({ component }) => {
  const { title, documents, cta } = component;

  const { width } = useWindowSize();

  return (
    <DocumentsWrapper>
      <Container
        style={{ position: 'initial' }}
        containerBackgroundColor="transparent"
        topSpacingMobile="119"
        bottomSpacingMobile="68"
        topSpacingDesktop="196"
        bottomSpacingDesktop="130"
        cap="1256"
      >
        <InformationContainer>
          {title && (
            <SectionTitle
              lightdarkMode={false}
              text={title}
              maxWidth={width >= 960 ? 582 : 203}
            />
          )}
        </InformationContainer>
        {documents?.length > 0 && (
          <DocumentsContainer>
            <Documents>
              {documents?.map((document) => {
                const body = getRichText(document?.body);

                return (
                  <ContentAndButtonCard
                    key={document?.contentful_id}
                    title={document?.title}
                    text={body}
                    head={true}
                    collapsed={false}
                  >
                    <DownloadButton
                      href={document?.image?.file?.url}
                      download={document?.image?.title}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {cta}
                    </DownloadButton>
                  </ContentAndButtonCard>
                );
              })}
            </Documents>
          </DocumentsContainer>
        )}
      </Container>
    </DocumentsWrapper>
  );
};

DocumentsModule.propTypes = {
  component: PropTypes.object.isRequired,
};

DocumentsModule.defaultProps = {
  component: {},
};

export default DocumentsModule;
