import React, { useState, useEffect } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useSwipeable } from 'react-swipeable';

import Container from 'components/Container';
import MediaAsset from 'components/UI/MediaAsset';
import SectionTitle from 'components/UI/SectionTitle';
import Typography from '../UI/Typography';
import AluproteckIcons from 'components/AluproteckIcons/AluproteckIcons';
import {
  ImageWrapper,
  TableContainer,
  HeaderBlock,
  Table,
  Cell,
  RowTitle,
  HeaderCell,
  TableMobile,
  TableMobileRow,
  MobileRowText,
} from './styles';
import theme from '../../utils/styles/theme';

const ComparativeTableModule = ({ component }) => {
  const { columns, image, rows, title } = component;

  const isMobile = useMediaQuery('(max-width:960px)');

  const [mobileActiveRow, setMobileActiveRow] = useState({
    index: 0,
    coincidence: [],
  });

  const swipeHandlers = useSwipeable({
    onSwipedRight: () => swipeMobileRow('right'),
    onSwipedLeft: () => swipeMobileRow('left'),
  });

  useEffect(() => {
    swipeMobileRow();
  }, []);

  const swipeMobileRow = (direction) => {
    const coincidence = [];
    const { index } = mobileActiveRow;
    let activeIndex = index;

    if (direction === 'right') {
      activeIndex = index - 1;
    } else if (direction === 'left') {
      activeIndex = index + 1;
    }

    if (activeIndex < 0) {
      activeIndex = rows.length - 1;
    } else if (activeIndex > rows.length - 1) {
      activeIndex = 0;
    }

    columns.map((column) => {
      rows[activeIndex].search(column) !== -1 && coincidence.push(column);
    });
    setMobileActiveRow({
      index: activeIndex,
      coincidence,
    });
  };

  return (
    <Container
      containerBackgroundColor={theme.colors.light}
      topSpacingDesktop="113"
      bottomSpacingDesktop="113"
      cap="1256"
    >
      {title && (
        <HeaderBlock>
          <SectionTitle text={title} maxWidth={604} displaced />
        </HeaderBlock>
      )}
      <TableContainer>
        {image && (
          <ImageWrapper className="ImageWrapper">
            <MediaAsset animated isLight image={image} />
          </ImageWrapper>
        )}
        {!isMobile ? (
          <Table>
            <div />
            {columns.map((column, i) => (
              <HeaderCell key={`${column}_${i}`}>
                <Typography elementTheme="bold">{column}</Typography>
              </HeaderCell>
            ))}
            {rows.map((row, index) => {
              const coincidence = [];
              columns.map((column) => {
                row.search(column) !== -1 && coincidence.push(column);
              });

              return (
                <>
                  <RowTitle key={`${row}_${index}`}>
                    <Typography>{row.split('|')[0]}</Typography>
                  </RowTitle>
                  {columns.map((column, i) => (
                    <Cell
                      key={`${column}_${i}`}
                      even={i % 2}
                      rowEven={index % 2}
                    >
                      {coincidence.map(
                        (result) =>
                          result === column && (
                            <AluproteckIcons
                              type={i % 2 ? 'check-mark-bw' : 'check-mark'}
                            />
                          )
                      )}
                    </Cell>
                  ))}
                </>
              );
            })}
          </Table>
        ) : (
          <TableMobile {...swipeHandlers}>
            <TableMobileRow>
              <div onClick={() => swipeMobileRow('left')}>
                <AluproteckIcons type="left-arrow" />
              </div>
              <MobileRowText elementTheme="h4" header>
                {rows[mobileActiveRow.index].split('|')[0]}
              </MobileRowText>
              <div onClick={() => swipeMobileRow('right')}>
                <AluproteckIcons type="right-arrow" />
              </div>
            </TableMobileRow>
            {columns.map((column, i) => (
              <TableMobileRow key={`${column}_${i}`}>
                <MobileRowText elementTheme="h4">{column}</MobileRowText>
                {mobileActiveRow.coincidence.map(
                  (result) =>
                    result === column && (
                      <AluproteckIcons
                        type={i % 2 ? 'check-mark-bw' : 'check-mark'}
                      />
                    )
                )}
              </TableMobileRow>
            ))}
          </TableMobile>
        )}
      </TableContainer>
    </Container>
  );
};

export default ComparativeTableModule;
