import React, { useEffect, useContext } from 'react';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import loadable from '@loadable/component';

import LocaleContext from 'context/LocaleProvider';
import HeaderContext from 'context/HeaderProvider';

// import ThreeDSliderModule from 'components/ThreeDSliderModule';
// import ServicesListModule from 'components/ServicesListModule';
import HeroModule from 'components/HeroModule';
import ContentAndImageModule from 'components/ContentAndImageModule';
// import SliderModule from 'components/SliderModule';
import CtaBannerModule from 'components/CtaBannerModule';
import VideoHeroModule from 'components/VideoHeroModule';
import ComparativeTableModule from 'components/ComparativeTableModule';
import AnodizingModule from 'components/AnodizingModule';
import StatisticsModule from 'components/StatisticsModule';
import SmallImagesHero from 'components/SmallImagesHero';
import ContactHeroModule from 'components/ContactHeroModule';
import PostsGridModule from 'components/PostsGridModule';
import Generic from 'components/Generic';
import Article from 'components/Article';
import DropdownsModule from 'components/DropdownsModule';
import DocumentsModule from 'components/DocumentsModule';
import AccordionModule from 'components/AccordionModule';
import {OverflowContainer} from '../ThreeDSliderModule/styles';

import { formatFrMonth } from 'utils/functions/formatFrMonth';

const ThreeDSliderModule = loadable(
  () => import('components/ThreeDSliderModule'),
  {
    fallback: <div />,
  }
);

const ServicesListModule = loadable(
  () => import('components/ServicesListModule'),
  {
    fallback: <div />,
  }
);

const SliderModule = loadable(() => import('components/SliderModule'), {
  fallback: <div />,
});

const PageLayout = (props) => {
  const { setLocalePageContext, setSelectedLocale, selectedLocale } =
    useContext(LocaleContext);
  const { setDarkHeader } = useContext(HeaderContext);
  const materialTheme = useTheme();
  const isMobile = useMediaQuery(materialTheme.breakpoints.down('600'));
  const node = props?.data?.allContentfulPage?.edges[0]?.node
  console.log(node)
  const { location } = props;

  useEffect(() => {
    const matchingPages =
      props?.matchingPages || props?.pageContext?.matchingPages;
    const nodeLocale = node?.node_locale;

    if (matchingPages) {
      setLocalePageContext(matchingPages);
    } else {
      setLocalePageContext(null);
    }

    if (nodeLocale !== selectedLocale) {
      setSelectedLocale(nodeLocale);
    }

    setDarkHeader(location?.pathname === '/' ? true : node?.enableDarkHeader);

  }, [location?.pathname, node]);

  return (
    <div style={{ minHeight: '700px' }}>
      {node?.modules?.map((component) => {
        switch (component.__typename) {
          case 'ContentfulArticleModule':
            return (
              <div style={{overflow: 'hidden'}}>
                <Article
                  title={component?.title}
                  author={component?.author}
                  date={
                    selectedLocale.includes('en')
                      ? component?.enDate
                      : formatFrMonth(component?.frDate)
                  }
                  excerpt={component?.excerpt}
                  tags={component?.tags}
                  image={
                    isMobile ? component?.mobileImage : component?.desktopImage
                  }
                  thumbnailImage={component?.thumbnailImage}
                  body={component?.articleBody}
                  key={component.id}
                  location={location}
                />
              </div>
            );
          case 'ContentfulGenericModule':
            return (
              <Generic
                key={component.id}
                title={component?.title}
                body={component?.body}
              />
            );
          case 'Contentful3DSliderModule':
            return (
              <OverflowContainer id="our-finishes">
                {component && (
                  <ThreeDSliderModule key={component.id} component={component} />
                )}
              </OverflowContainer>
            )
          case 'ContentfulAccordionModule':
            return <AccordionModule key={component.id} component={component} />;
          case 'ContentfulAnodizingModule':
            return <AnodizingModule key={component.id} component={component} />;
          case 'ContentfulComparativeTableModule':
            return (
              <ComparativeTableModule
                key={component.id}
                component={component}
              />
            );
          case 'ContentfulContactHeroModule':
            return (
              <ContactHeroModule key={component.id} component={component} />
            );
          case 'ContentfulContentAndImageModule':
            return (
              <div style={{overflow: 'hidden'}}>
                <ContentAndImageModule key={component.id} component={component} />
              </div>
            );
          case 'ContentfulCtaBannerModule':
            return <CtaBannerModule key={component.id} component={component} />;
          case 'ContentfulDocumentsModule':
            return <DocumentsModule key={component.id} component={component} />;
          case 'ContentfulDropdownsModule':
            return <DropdownsModule key={component.id} component={component} />;
          case 'ContentfulEntryHeroModule':
            return (
              <HeroModule
                key={component.id}
                title={component?.title}
                description={component?.EntryHeroBody}
                scrollText={component?.scrollText}
                scrollSecondary={component?.scrollTextSecondary}
                videoYoutubeUrl={component?.videoYoutubeUrl}
                caption={component?.caption}
                videoFile={component?.video}
                skip={component?.skip}
                textSlides={component?.textSlides}
              />
            );
          case 'ContentfulPostsGridModule':
            return (
              <PostsGridModule
                key={component.id}
                component={component}
                articles={props?.data?.allContentfulArticleModule?.edges}
              />
            );
          case 'ContentfulServicesListModule':
            return (
              <div style={{overflow: 'hidden'}}>
                <ServicesListModule component={component} />
              </div>
            )
          case 'ContentfulSliderModule':
            const isBlogSnippet = component?.cards?.every(
              (card) => card.articleBody
            );

            const relatedArticles = isBlogSnippet
              ? props?.data?.allContentfulArticleModule?.edges.slice(0, 3)
              : [];

            return (
              <SliderModule
                key={component.id}
                articles={relatedArticles}
                component={component}
              />
            );
          case 'ContentfulSmallImagesHeroModule':
            return <SmallImagesHero key={component.id} component={component} />;
          case 'ContentfulStatisticsModule':
            return (
              <StatisticsModule key={component.id} component={component} />
            );
          case 'ContentfulVideoHeroModule':
            return <VideoHeroModule key={component.id} component={component} />;
          default:
            return <></>;
        }
      })}
    </div>
  );
};

export default PageLayout;
