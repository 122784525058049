import React from 'react';
import FileBase64 from 'react-file-base64';
import AluproteckIcons from 'components/AluproteckIcons/AluproteckIcons';
import { FakeInput, FileInputLabel, FileInputContainer } from './styles';

const FileInput = ({ fileUpload, state, setState }) => {
  // const handleChange = (e) => {
  //   console.log(e.target);
  //   setState({ ...state, files: e.target.files });
  // };

  return (
    <FileInputContainer>
      <FileInputLabel htmlFor="upload-file">
        <AluproteckIcons type="upload" />
        <span style={{ maxWidth: 180, overflow: 'hidden' }}>
          {state?.files?.name ? state.files.name : fileUpload}
        </span>
      </FileInputLabel>
      <FakeInput>
        <FileBase64
          id="upload-file"
          onDone={(files) => setState({ ...state, files })}
          name="upload-file"
        />
      </FakeInput>
    </FileInputContainer>
  );
};

export default FileInput;
