import styled from 'styled-components';
import Typography from 'components/UI/Typography';
import Button from 'components/UI/Button';
import hexToRGB from '../../../utils/functions/hexToRGB';

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 31px 65px 54px 65px;
  background-color: ${(props) => props.theme.colors.light};
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    padding: 45px 35px 51px 35px;
    width: 100%;
  }
`;

export const FormHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    flex-direction: column;
    align-items: flex-start;
  }

  > *:first-child {
    padding-right: 20px;
  }
`;

export const Title = styled(Typography)`
  font-weight: 700 !important;
  font-family: ${(props) => props.theme.fontFamily.recursive};
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    margin-bottom: 26px;
  }
`;

export const CtaButton = styled(Button)`
  padding: 16px;
  font-family: ${(props) => props.theme.fontFamily.roboto_mono};
  background-color: ${(props) => hexToRGB(props.theme.colors.secondary, 0.5)};
  & svg {
    margin-right: 10px;
  }
`;

export const SubmitButton = styled(Button)`
  padding: ${props => props?.hasError ? `14px` : `14px 54.5px`};
  min-width: ${props => props?.hasError ? '175px' : '100px'};
  background: ${props => props?.hasError ? props.theme.colors.error : props.theme.colors.secondary};
  color: ${props => props?.hasError ? `${props.theme.colors.light} !important` : `${props.theme.colors.primary} !important`};
  margin-left: 32px !important;
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    margin-left: 0 !important;
    margin-top: 38px !important;
  }
`;

export const SuccesButton = styled(Button)`
  padding: 14px 54.5px;
  width: 386px;
  background-color: ${(props) => props.theme.colors.success};
  margin-top: 200px;

  &:hover {
    background-color: ${(props) => hexToRGB(props.theme.colors.success, 0.95)};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    width: 100%;
  }
`;

export const FormBlock = styled.form`
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    margin-top: 43px;
  }

  > * > *:not(button) {
    width: 100%;
  }
`;

export const InputBlock = styled.div`
  display: flex;
  position: relative;

  input,
  textarea {
    background: ${(props) => props.theme.colors.light};
  }
`;

export const Error = styled.span`
  position: absolute;
  z-index: 2;
  font-size: 16px;
  color: ${(props) => props.theme.colors.error};
  right: 0;
  bottom: 9px;
`;

export const StyledInput = styled.input`
  min-width: 386px;
  padding-bottom: 9px;
  margin-top: 32px;
  border-bottom: 1px solid
    ${(props) =>
      props.error ? props.theme.colors.error : props.theme.colors.primary};

  font-size: 16px;

  z-index: 1;

  &::placeholder {
    color: ${(props) => props.theme.colors.primary};
    opacity: 0.5;
  }

  &:focus {
    outline: none;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    min-width: initial;
    width: 100%;
  }
`;

export const StyledSelect = styled.select`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.5' d='M1.41 -4.62904e-07L6 4.58L10.59 -6.16331e-08L12 1.41L6 7.41L-6.16331e-08 1.41L1.41 -4.62904e-07Z' fill='%23222121'/%3E%3C/svg%3E%0A")
    no-repeat 97% 50%;

  min-width: 386px;
  padding-bottom: 9px;
  margin-top: 32px;
  cursor: pointer;
  border-bottom: 1px solid
    ${(props) =>
      props.error ? props.theme.colors.error : props.theme.colors.primary};

  font-size: 16px;
  color: ${(props) =>
    props.inputValue
      ? props.theme.colors.primary
      : hexToRGB(props.theme.colors.primary, 0.5)};

  &:focus {
    outline: none;
  }

  &::placeholder {
    background-color: red;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    min-width: initial;
    width: 100%;
  }
`;

export const StyledOption = styled.option`
  font-size: 16px;
  color: ${(props) => props.theme.colors.primary};
`;

export const StyledTextArea = styled.textarea`
  min-width: 386px;
  height: 32px;
  margin-top: 32px;
  border-bottom: 1px solid
    ${(props) =>
      props.error ? props.theme.colors.error : props.theme.colors.primary};

  font-size: 16px;

  &:focus {
    outline: none;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    min-width: initial;
    width: 100%;
  }
`;

export const FormButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 27px;
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    flex-direction: column;
    margin-top: 47px;
  }
`;

export const FakeInput = styled.div`
  opacity: 0;
  position: relative;
  width: 100%;
  height: 100%;

  * {
    cursor: pointer;
    position: absolute;
    left: -115px;
    top: -15px;
    width: 125px;
  }
`;

export const FileInputContainer = styled.div`
  cursor: pointer;
`

export const FileInputLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0;

  & > svg {
    margin-right: 9px;
  }
`;

export const MapWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #2d2d2c;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  @media (max-width: ${(props) => props.theme.breakpoints.l}) {
    height: 100vh;
  }
`;

export const GradientBlock = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background: ${(props) =>
    props?.mapLoaded
      ? 'linear-gradient(90deg, rgba(34, 33, 33, 0) 30%, rgba(34, 33, 33, 1) 60%)'
      : 'rgba(34, 33, 33, 1)'};

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    background: ${(props) =>
      props?.mapLoaded
        ? 'linear-gradient(180deg, rgba(34, 33, 33, 0) 50%, rgba(34, 33, 33, 1) 75%)'
        : 'rgba(34, 33, 33, 1)'};
  }
`;

export const SuccesBlock = styled.div`
  display: flex;
  flex-direction: column;
  & > h2 {
    font-family: ${(props) => props.theme.fontFamily.recursive};
    font-weight: 700 !important;
  }
  & > p {
    font-family: ${(props) => props.theme.fontFamily.recursive};
    font-weight: 700 !important;
    color: ${(props) => hexToRGB(props.theme.colors.primary, 0.6)};
    max-width: 234px;
    margin-top: 5px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.l}) {
    /* height: 100vh; */
  }
`;
