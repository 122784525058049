import React, { useEffect, useState, useContext } from 'react';
import { gsap, Power1 } from 'gsap';
import { SplitText } from '@cyriacbr/react-split-text';
import { useInView } from 'react-intersection-observer';
import LoaderContext from 'context/LoaderProvider';

const initAnimation = (ref) => {
  const letters = ref.querySelectorAll('#letter');

  gsap
    .timeline()
    .fromTo(
      letters,
      {
        autoAlpha: 0,
      },
      {
        autoAlpha: 1,
        stagger: 0.06,
        duration: 0.4,
        ease: Power1.easeOut,
      },
      0
    )
    .fromTo(
      letters,
      {
        x: '50%',
      },
      {
        x: 0,
        stagger: 0.05,
        duration: 0.5,
        ease: Power1.easeOut,
      },
      0
    );
}

const AnimatedSplitTitle = ({ children, isAwaitingLoading }) => {
  const { hasLoaded } = useContext(LoaderContext);
  const [ref, inView, entry] = useInView({
    threshold: 0,
  });

  const [splitTextRef, setSplitTextRef] = useState(null);

  useEffect(() => {
    if (inView && splitTextRef && isAwaitingLoading && hasLoaded) {
      initAnimation(splitTextRef)
    } else if (!isAwaitingLoading && inView && splitTextRef) {
      initAnimation(splitTextRef)
    }
  }, [inView, splitTextRef, hasLoaded]);

  return (
    <div style={{ opacity: inView ? 1 : 0 }} ref={ref}>
      <SplitText
        ref={(textRef) => setSplitTextRef(textRef)}
        LetterWrapper={({ children }) => (
          <span
            id="letter"
            style={{
              display: 'inline-block',
            }}
          >
            {children}
          </span>
        )}
      >
        {children}
      </SplitText>
    </div>
  );
};

export default AnimatedSplitTitle;
