import styled from 'styled-components';
import Typography from 'components/UI/Typography';
import Button from 'components/UI/Button';
import hexToRGB from '../../utils/functions/hexToRGB';

export const HeroWrapper = styled.div`
  position: relative;
`;

export const ContentContainer = styled.div`
  width: ${(props) => (props.enableGradientOnImage ? '100%' : '50%')};
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: space-between;
  z-index: 2;
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    width: 100%;
  }
`;

export const TitleBlock = styled.div`
  max-width: 800px;
  margin-top: 44px;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    margin-bottom: 25px;
    margin-top: 25px;
  }
`;

export const SecondaryTitle = styled(Typography)`
  font-family: ${(props) => props.theme.fontFamily.recursive};
  max-width: 543px;
  margin-top: 25px;
  line-height: 1.2 !important;
`;

export const BodyText = styled(Typography)`
  max-width: 443px;
  margin-top: 26px;
  margin-bottom: 43px;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    margin-bottom: 30px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  margin-bottom: 60px;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    margin-bottom: 0;
  }
`;

export const CtaButton = styled(Button)`
  font-family: ${(props) => props.theme.fontFamily.roboto_mono};
  padding: 15px 31.5px;
  & svg {
    margin-right: 10px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.xs}) {
    width: 100%;
    & svg {
      margin-right: 0;
      margin-left: 10px;
    }
  }
`;

export const Wrapper = styled.div`
  width: 100vw;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    display: flex;
    flex-direction: column;
    width: 100%;

    > *:first-child {
      order: 2;
    }
    > *:last-child {
      order: 1;
    }
  }
`;

export const ImageWrapper = styled.div`
  position: absolute;
  width: 100%;
  max-width: ${(props) => (props.enableGradientOnImage ? '50%' : '620px')};
  height: auto;
  margin: ${(props) =>
    props.enableGradientOnImage
      ? '104px auto 137px auto'
      : '160px auto 137px auto'};
  top: 0;
  right: 0;
  z-index: 1;
  overflow: hidden;
  display: flex;
  justify-content: flex-end;

  @media (max-width: ${(props) => props.theme.breakpoints.l}) {
    width: calc(100% - 300px);
    max-width: initial;
    max-height: 450px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    width: calc(100% + 40px);
    margin: 0 0 20px -20px;
    position: relative;
    max-height: initial;

    & > .gatsby-image-wrapper {
      width: 100%;
    }
  }
`;

export const GradientBlock = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: -50%;
  bottom: 0;
  z-index: 2;
  background: linear-gradient(
    90deg,
    rgba(240, 240, 240, 1) 50%,
    rgba(240, 240, 240, 0) 100%
  );

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    left: -20px;
    width: calc(100% + 40px);
    background: linear-gradient(
      0deg,
      rgba(240, 240, 240, 1) 10%,
      rgba(240, 240, 240, 0) 100%
    );
  }
`;

export const VideoPopup = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  background-color: ${(props) => hexToRGB(props.theme.colors.primary, 0.8)};

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${(props) => props.theme.breakpoints.xl}) {
    background-color: ${(props) => props.theme.colors.primary};
  }
`;

export const VideoContainer = styled.div`
  width: 800px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 32px 35px;
  background-color: ${(props) => props.theme.colors.primary};

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    width: 100%;
    padding: 0;
    background: none;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  margin-bottom: 28px;
  cursor: pointer;

  @media (max-width: ${(props) => props.theme.breakpoints.xl}) {
    position: absolute;
    top: 25px;
    right: 20px;
  }
`;
