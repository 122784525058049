import React from 'react';
import { navigate } from 'gatsby';

import MediaAsset from 'components/UI/MediaAsset';

import {
  CardContainer,
  ImageWrapper,
  CardImage,
  TextBlock,
  PostCardTitle,
} from '../styles';

import getPageRoute from '../../../utils/functions/getPageRoute';

const PostCard = ({ excerpt, image, link, title }) => {
  const postClick = () => {
    if (link) {
      navigate(getPageRoute(link));
    }
  };

  return (
    <CardContainer onClick={postClick} link={link}>
      {image && (
        <ImageWrapper>
          <CardImage>
            <MediaAsset image={image} />
          </CardImage>
        </ImageWrapper>
      )}
      <PostCardTitle elementTheme="h4 bold">{title}</PostCardTitle>
      <TextBlock elementTheme="small">{excerpt}</TextBlock>
    </CardContainer>
  );
};

export default PostCard;
