import styled from 'styled-components';

export const DropdownsWrapper = styled.div``;

export const InformationContainer = styled.div`
  margin-bottom: 52px;
  width: 100%;
  display: flex;
  justify-content: flex-start;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    margin-bottom: 107px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    padding: 0 106px;
  }
`;

export const DropdownsContainer = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    padding: 0 106px;
    width: 100%;
  }
`;

export const Dropdowns = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-row-gap: 29px;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    grid-row-gap: 20px;
  }
`;
