import React from 'react';

const MapPoint = () => {
  return (
    <svg
      width="70"
      height="50"
      viewBox="0 0 70 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: 'translate(-50%, -50%)' }}
    >
      <path
        d="M33.3929 0H20.8964L24.4493 10.6588L24.9394 12.0065C25.3069 13.4766 25.5519 14.9468 25.5519 16.5395C25.5519 22.2977 22.6116 27.4433 18.0785 30.3837C16.6083 31.3638 15.1382 32.0989 13.423 32.589C13.423 32.589 13.423 32.589 13.3004 32.589H13.1779C11.2177 33.079 9.25745 33.079 7.78727 33.2015H7.66476C3.00919 33.5691 -0.543741 37.7346 0.0688332 42.6352C0.558893 46.1881 3.37673 49.1285 7.05218 49.7411C11.7077 50.4761 15.8733 47.1682 16.6083 42.8802C16.8534 41.2875 16.9759 38.8372 17.711 37.2445C18.4461 36.0194 19.4262 34.9168 20.5288 34.0591C21.8765 33.079 23.5917 32.589 25.4294 32.589C29.2274 32.589 32.5353 35.0393 33.7604 38.4697V38.5922L37.4359 49.7411H49.9324L33.3929 0Z"
        fill="#F0F0F0"
      />
      <path
        d="M62.7781 27C59.0341 27 56 29.9779 56 33.6499C56 37.3229 62.7781 49.368 62.7781 49.368C62.7781 49.368 69.5566 37.3229 69.5566 33.6499C69.5566 29.9779 66.5206 27 62.7781 27Z"
        fill="#FFD600"
      />
    </svg>
  );
};

export default MapPoint;
