import styled from 'styled-components';
import Typography from 'components/UI/Typography';
import Button from 'components/UI/Button';
import hexToRGB from '../../utils/functions/hexToRGB';

export const PostsGridHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    margin-top: 25px;
  }
`;

export const FilterTitle = styled(Typography)`
  font-weight: 700 !important;
  font-family: ${(props) => props.theme.fontFamily.recursive};
  margin-right: 13px;
`;

export const FilterBlock = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const FilterText = styled(Typography)`
  font-weight: 700 !important;
  font-family: ${(props) => props.theme.fontFamily.roboto_mono};
  color: ${(props) =>
    hexToRGB(props.theme.colors.primary, props.active ? 1 : 0.8)};
  border-bottom: ${(props) =>
    props.active && `1px solid ${props.theme.colors.primary}`};
  margin-left: 7px;
  cursor: pointer;
`;

export const PostCardTitle = styled(Typography)`
  margin: 33px 0;
  line-height: 1.3 !important;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    margin: 24px 0;
  }
`;

export const PostsGridBlock = styled.div`
  width: 100%;
  display: flex;
  column-gap: 15px;
  flex-wrap: wrap;
  margin-top: 60px;
  justify-content: flex-start;

  @media (max-width: ${(props) => props.theme.breakpoints.l}) {
    margin-top: 30px;
    justify-content: center;
  }
`;

export const CardContainer = styled.div`
  max-width: calc(33% - 6.5px);
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  cursor: ${(props) => (props.link ? 'pointer' : 'default')};

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    max-width: calc(50% - 8px);
    margin-top: 35px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.s}) {
    max-width: 100%;
  }

  .gatsby-image-wrapper {
    transition: 0.7s ease;
    transition-property: transform;
  }
  :hover {
    .gatsby-image-wrapper {
      transform: scale(1.2);
    }
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;
  padding-top: 56.25%;
  position: relative;
  height: 0;
  overflow: hidden;
`;

export const CardImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;

  & > .gatsby-image-wrapper {
    height: 100%;
  }
`;

export const TextBlock = styled(Typography)`
  opacity: 0.8;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 70px;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    margin-top: 40px;
  }
`;

export const ButtonOutline = styled(Button)`
  background: none;
  padding: 13px 39.5px;
  border: 1px solid ${(props) => props.theme.colors.primary};
  text-transform: uppercase;

  &:hover {
    background: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.light};
  }
`;
