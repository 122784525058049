import styled from 'styled-components';
import Typography from 'components/UI/Typography';

export const AccordionWrapper = styled.div`
  width: 100%;
`;

export const InformationContainer = styled.div`
  margin-right: auto;
  margin-bottom: 36px;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    margin-bottom: 60px;
  }
`;

export const AccordionContainer = styled.div`
  width: 100%;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    display: grid;
    grid-column-gap: 60px;
    grid-template-columns: 1fr 49.364%;
  }
`;

export const ImageContainer = styled.div``;

export const Accordions = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    padding-top: 20px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    padding-left: 65px;
  }
`;
