import styled from 'styled-components';

export const DocumentsWrapper = styled.div``;

export const InformationContainer = styled.div`
  margin-bottom: 65px;
  width: 100%;
  display: flex;
  justify-content: flex-start;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    margin-bottom: 130px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    padding: 0 106px;
  }
`;

export const DocumentsContainer = styled.div`
  width: 100%;
`;

export const Documents = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-row-gap: 29px;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    grid-row-gap: 30px;
  }
`;

export const DownloadButton = styled.a`
  padding: 13px;
  width: 100%;
  height: 45px;
  font-family: ${(props) => props.theme.fontFamily.roboto_mono};
  font-size: ${(props) => props.theme.fonts.overline_big};
  font-weight: 400;
  line-height: 1.3;
  text-transform: uppercase;
  text-align: center;
  color: ${(props) => props.theme.colors.darkgrey};
  border: 1px solid ${(props) => props.theme.colors.primary};
  transition: 300ms ease;
  will-change: background-color;
  transition-property: background-color;

  :hover {
    color: ${(props) => props.theme.colors.light};
    background: ${(props) => props.theme.colors.primary};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    height: 46px;
  }
`;
