import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import { ContainerIframe, WrapperIframe, PlayIconWrapper } from './styles';

import AluproteckIcons from 'components/AluproteckIcons/AluproteckIcons';

const PopupVideo = ({ videoSrc }) => {
  const [playing, setPlaying] = useState(false);

  const playVideo = () => {
    setPlaying(true);
  };

  const stopVideo = () => {
    setPlaying(false);
  };

  const videoClick = () => {
    setPlaying((prev) => !prev);
  };

  return (
    <WrapperIframe onClick={videoClick}>
      <ContainerIframe>
        {!playing && (
          <PlayIconWrapper>
            {/* <AluproteckIcons type="play-video" /> */}
          </PlayIconWrapper>
        )}
        <ReactPlayer
          className="responsive-iframe"
          url={videoSrc}
          playing={playing}
          config={{
            youtube: {
              playerVars: { disablekb: 1, modestbranding: 0, controls: 1 },
            },
          }}
          muted={false}
        />
      </ContainerIframe>
    </WrapperIframe>
  );
};

export default PopupVideo;
