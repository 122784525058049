import { useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';

export const useDeviceDetect = () => {
  const [isMobile, setMobile] = useState(false);
  const theme = useTheme();
  const isWidthMobile = useMediaQuery(theme.breakpoints.down('600'));

  useEffect(() => {
    const isBrowser = typeof window !== `undefined`;

    if (!isBrowser) return null;
    const mobile = Boolean(
      'ontouchstart' in window || navigator.maxTouchPoints && isWidthMobile
    );
    setMobile(mobile);
  }, []);

  return { isMobile };
};
