import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Root = styled.div``;

const Background = styled.div`
  background: linear-gradient(
    270.22deg,
    rgb(0, 0, 0) 0.74%,
    rgb(105, 88, 54) 51.28%,
    rgb(201, 201, 201) 99.81%
  );
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  opacity: ${(props) => props.opacity};
  transition: opacity 1s ease;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .progress {
    background-color: #222121;
    border-radius: 0;
    position: relative;
    height: 5px;
    width: 100%;
  }

  .progress-done {
    background: #f0f0f0;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 0;
    opacity: 0;
    transition: 1s ease 0.3s;
  }
`;

const ClientOnly = ({ children }) => {
  const [hasMounted, setHasMounted] = useState(false);
  const [style, setStyle] = useState(null);
  const [opacity, setOpacity] = useState(1);
  const done = 100;

  useEffect(() => {
    if (!style) {
      setStyle({
        opacity: 1,
        width: `${done}%`,
      });
    }

  }, [style]);
  
  useEffect(() => {
    setTimeout(() => {
      setOpacity(0);
    }, 1000)
    setTimeout(() => {
      setHasMounted(true);
    }, 2000);
  }, [])

  return (
    <>
      {!hasMounted && (
        <Root>
          <Background opacity={opacity}>
            <div className="progress">
              <div className="progress-done" style={style}></div>
            </div>
            <svg
              width="87"
              height="87"
              viewBox="0 0 87 87"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M58.1823 0H36.4089L42.5994 18.5714L43.4532 20.9195C44.0936 23.4811 44.5206 26.0427 44.5206 28.8177C44.5206 38.8506 39.3974 47.8161 31.4992 52.9392C28.9376 54.6469 26.3761 55.9277 23.3876 56.7816C23.3876 56.7816 23.3876 56.7816 23.1741 56.7816H22.9606C19.5452 57.6354 16.1298 57.6354 13.5682 57.8489H13.3547C5.24308 58.4893 -0.947391 65.7471 0.119932 74.2857C0.97379 80.4761 5.88347 85.5993 12.2874 86.6666C20.3991 87.9474 27.6569 82.1839 28.9376 74.7126C29.3646 71.9376 29.578 67.6683 30.8588 64.8932C32.1396 62.7586 33.8473 60.8374 35.7685 59.3432C38.1166 57.6354 41.1051 56.7816 44.3071 56.7816C50.9245 56.7816 56.688 61.0509 58.8227 67.0279V67.2413L65.2266 86.6666H87L58.1823 0Z"
                fill="#222121"
              />
            </svg>
          </Background>
        </Root>
      )}
      {children}
    </>
  );
};

ClientOnly.propTypes = {
  children: PropTypes.any,
};

export default ClientOnly;
