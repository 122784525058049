import styled, { keyframes } from 'styled-components';
import Typography from 'components/UI/Typography';
import { motion, m } from 'framer-motion';
import { StickyContainer } from 'react-sticky';

export const HeroWrapper = styled.div`
  position: relative;
`;

export const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    padding: 0 35px;
  }

  @media (min-width: 1250px) {
    padding: 0;
  }
`;

export const InformationContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 100px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    padding-bottom: 130px;
  }
`;

export const TitleBlock = styled.div`
  max-width: 625px;
`;

export const Title = styled(Typography)`
  /* font-family: ${(props) => props.theme.fontFamily.recursive}; */
  color: ${(props) => props.theme.colors.light};
  max-width: 625px;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    margin-bottom: 34px;
  }
`;

export const ScrollText = styled(Typography)`
  font-family: ${(props) => props.theme.fontFamily.roboto_mono};
  color: ${(props) => props.theme.colors.light};
`;

export const Description = styled.p`
  font-family: ${(props) => props.theme.fontFamily.primary};
  color: ${(props) => props.theme.colors.light};
  max-width: 498px;
  opacity: 0.8;
`;

export const InfoRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
  margin-bottom: 35px;
  border-top: 1px solid ${(props) => props.theme.colors.light};

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    flex-direction: column;
    margin-bottom: 10px;
  }
`;

export const CaptionText = styled(Typography)`
  font-family: ${(props) => props.theme.fontFamily.roboto_mono};
  color: ${(props) => props.theme.colors.light};
  opacity: 0.8;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    margin-bottom: 36px;
  }
`;

const goUp = keyframes`
  0% {
    top: 0;
    opacity: 1;
  }
  50% {
    top: 0;
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
  100% {
    top: 22px;
  }
`;

export const ScrollContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  height: 20px;
  overflow: hidden;
  max-width: 240px;
  text-transform: uppercase;
  opacity: 0.8;

  svg {
    margin: 2px 0 0 15px;
    position: relative;
    animation: ${goUp} 1.2s ease-in-out infinite;

    path {
      fill: ${(props) => props.theme.colors.light};
    }
  }
`;

// export const Video = styled(m.div)`
//   width: 100vw;
//   position: absolute;
//   top: 0;
//   left: 0;
// `;

export const VideoContainer = styled(motion.div)`
  position: sticky;
  top: 0;
  height: 100vh;

  will-change: clip-path;
  width: 100%;
  // margin: -92px auto 0;
  margin: 0 auto;
  transition: 400ms;
  z-index: 2;
  transition-property: clip-path, transform;
  transform: ${(props) =>
    props.isCompleted ? 'translateY(0)' : 'translateY(-92px)'};

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    transform: ${(props) =>
      props.isCompleted ? 'translateY(0)' : 'translateY(-40px)'};
  }

  & video {
    width: auto !important;
  }
`;

export const VideoFixedContainer = styled.div`
  position: relative;
`;

export const VideoContent = styled.div`
  height: 100vh;
  z-index: 2;
  position: relative;

  &.first{
    margin-top: -100vh;
  }
`;

export const ProgressBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  height: 5px;
  background: transparent;

  .progress-done {
    background: #f0f0f0;
    height: 100%;
    width: 100%;
    transform-origin: center left;
  }
`;

export const TextSlideWrapper = styled.div`
  position: absolute;
  bottom: 100px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
  max-width: 1450px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 0 98px;

  @media (max-width: ${(props) => props.theme.breakpoints.s}) {
    padding: 0 20px;
  }
`;

export const HeroDecorationElement = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;

  @media (max-width: ${(props) => props.theme.breakpoints.s}) {
    display: none;
  }
`;

export const ScrollItems = styled.div`
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 0;
  width: 100%;
  max-width: 1450px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 0 98px;

  @media (max-width: ${(props) => props.theme.breakpoints.s}) {
    padding: 0 20px;
  }
`;

export const ScrollMore = styled.div`
  width: 100%;
  max-width: 180px;
  display: flex;
  width: 100%;
`;

export const SkipButton = styled.button`
  width: 75px;
  height: 75px;
  border-radius: 100%;
  border: 1px solid ${(props) => props.theme.colors.light};
  color: ${(props) => props.theme.colors.light};
  background: transparent;
  outline: none !important;
  cursor: pointer;

  font-family: ${(props) => props.theme.fontFamily.roboto_mono};
  text-transform: uppercase;

  @media (max-width: ${(props) => props.theme.breakpoints.s}) {
    width: 50px;
    height: 50px;
  }
`;

export const LineScroll = styled.div`
  flex: 1;
  margin-right: 40px;
  border-top: 1px solid ${(props) => props.theme.colors.light};
  padding-top: 15px;
  margin-top: 36px;
`;

export const ContainerCta = styled.div`
  width: 100%;
  display: flex;
`;

export const TextSlidesWrapper = styled.div`
  height: 175px;
  overflow: hidden;
  position: relative;
`;

export const TextSlide = styled(Typography)`
  font-family: ${(props) => props.theme.fontFamily.recursive};
  color: ${(props) => props.theme.colors.light};
  width: 100%;
  max-width: 545px;
  line-height: 1.2em !important;

  position: absolute;
  transition: opacity 0.5s, bottom 0.5s;

  @media (max-width: 960px) {
    left: 98px;
    right: 98px;
    width: auto;
  }

  @media (max-width: 600px) {
    left: 20px;
    right: 20px;
    width: auto;
  }

  &.visible {
    opacity: 1;
    bottom: 0;
  }

  &.hide {
    bottom: 200px;
    opacity: 1;
  }
`;
