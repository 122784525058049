import React, { useState } from 'react';

import InlineVideo from 'components/InlineVideo';
import {
  ScrollText,
  ScrollContainer,
  ScrollItems,
  ScrollMore,
  LineScroll,
  ContainerCta,
  ProgressBar,
} from 'components/HeroModule/styles';
import AluproteckIcons from 'components/AluproteckIcons/AluproteckIcons';
import Container from 'components/Video/Container';
import ClientOnly from 'components/ClientOnly';

const Video = (props) => {
  const {
    scrollSecondary,
    videoYoutubeUrl,
    videoFile,
    refVideoProgress,
  } = props;

  const vidUrl = videoFile?.file?.url || videoYoutubeUrl;

  return vidUrl ? (
    <Container>
      <ProgressBar >
        <div ref={refVideoProgress} className="progress-done"></div>
      </ProgressBar>
      <InlineVideo videoSrc={vidUrl} />
      <ScrollItems>
        <ContainerCta>
          {scrollSecondary && (
            <LineScroll>
              <ScrollContainer>
                <ScrollMore>
                  <ScrollText elementTheme="caption">
                    {scrollSecondary}
                  </ScrollText>
                  <AluproteckIcons type="scroll-down" />
                </ScrollMore>
              </ScrollContainer>
            </LineScroll>
          )}
        </ContainerCta>
      </ScrollItems>
    </Container>
  ) : (
    <></>
  );
};

export default Video;
