import React, { useState, useEffect, useContext } from 'react';
import ReactPlayer from 'react-player/lazy';
import { ContainerIframe, WrapperIframe } from './styles';
import { useInView } from 'react-intersection-observer';
import LoaderContext from '../../context/LoaderProvider';

const InlineVideo = ({ videoSrc }) => {
  const [playing, setPlaying] = useState(false);
  const { setHasLoaded } = useContext(LoaderContext);
  const [ref, inView] = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (inView) {
      setPlaying(true);
    } else {
      setPlaying(false);
    }
  }, [inView]);

  return (
    <WrapperIframe ref={ref}>
      <ContainerIframe>
        <ReactPlayer
          className="responsive-iframe"
          url={videoSrc}
          playing={playing}
          muted={true}
          loop
          controls={false}
          playsinline={true}
          onReady={() => setHasLoaded(true)}
        />
      </ContainerIframe>
    </WrapperIframe>
  );
};

export default InlineVideo;
