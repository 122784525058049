import React, { useState } from 'react';
import AluproteckIcons from 'components/AluproteckIcons/AluproteckIcons';

import Input from './Input';
import FileInput from './FileInput';

import {
  FormContainer,
  FormHeader,
  Title,
  CtaButton,
  FormBlock,
  SubmitButton,
  FormButtons,
  SuccesBlock,
  SuccesButton,
} from './styles';

import getRoute from '../../../utils/functions/getRoute';
import getRichText from '../../../utils/functions/getRichText';

const ContactsForm = ({ form }) => {
  const {
    cta,
    ctaLink,
    fileUpload,
    fileError,
    inputs,
    secondarySuccess,
    submit,
    success,
    title,
  } = form;

  const [values, setValues] = useState({});
  const [sended, setSended] = useState(false);

  const validate = () => {
    const EMAIL_REGEXP = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const PHONE_REGEXP = /^[+]?(1\-|1\s|1|\d{3}\-|\d{3}\s|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/g;

    let result = true;

    for (const key in values) {
      if (key !== 'files') {
        if (values[key].type === 'email') {
          if (!EMAIL_REGEXP.test(values[key].value)) {
            setValues((prev) => {
              return {
                ...prev,
                [key]: {
                  value: values[key].value,
                  valid: false,
                },
              };
            });
            result = false;
          }
        } else if (values[key].type === 'tel') {
          if (!PHONE_REGEXP.test(values[key].value)) {
            setValues((prev) => {
              return {
                ...prev,
                [key]: {
                  value: values[key].value,
                  valid: false,
                },
              };
            });
            result = false;
          }
        } else if (!values[key].value) {
          setValues((prev) => {
            return {
              ...prev,
              [key]: {
                value: values[key].value,
                valid: false,
              },
            };
          });
          result = false;
        }
      }
    }

    return result;
  };

  const submitClick = async (e) => {
    e.preventDefault();
    if (validate()) {
      await window
        .fetch(`/.netlify/functions/email`, {
          method: `POST`,
          headers: {
            'content-type': 'application/json',
          },
          body: JSON.stringify(values),
        })
        .then((res) => {
          res.json();
          setSended(true);
        });
    } else {
      console.log('FAIL');
    }
  };

  const successClick = () => {
    setSended(false);
  };

  const fileSize = values?.files?.size ? +values?.files?.size?.split(' ')?.[0] : null

  return (
    <FormContainer>
      {!sended ? (
        <>
          <FormHeader>
            <Title elementTheme="h4">{title}</Title>
            {cta && ctaLink && (
              <CtaButton to={getRoute(ctaLink)} isExternal>
                <AluproteckIcons type="phone" />
                {cta}
              </CtaButton>
            )}
          </FormHeader>
          {inputs && (
            <FormBlock enctype="multipart/form-data" onSubmit={submitClick}>
              {inputs.map((input) => {
                return (
                  <Input
                    id={input.type}
                    state={values}
                    setState={setValues}
                    placeholder={input.placeholder}
                    type={input.type}
                    error={input.error}
                    selectOptions={input.selectOptions}
                    key={input.contentful_id}
                    name={input.name}
                  />
                );
              })}
              <FormButtons>
                {fileUpload && (
                  <FileInput
                    state={values}
                    setState={setValues}
                    fileUpload={fileUpload}
                  />
                )}
                <SubmitButton hasError={fileSize > 4000} disabled={fileSize > 4000} type="submit">{fileSize > 4000 ? fileError : submit}</SubmitButton>
              </FormButtons>
            </FormBlock>
          )}
        </>
      ) : (
        <>
          <SuccesBlock>{getRichText(success)}</SuccesBlock>
          {secondarySuccess && (
            <SuccesButton onClick={successClick}>
              {secondarySuccess}
            </SuccesButton>
          )}
        </>
      )}
    </FormContainer>
  );
};

export default ContactsForm;
