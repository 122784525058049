import React, { useEffect, useState } from 'react';
import { VideoContainer } from 'components/HeroModule/styles';
import { useInView } from 'react-intersection-observer';
import { useViewportScroll, useTransform } from 'framer-motion';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const Container = ({ children }) => {
  const isMobile = useMediaQuery('(max-width:960px)');
  const { ref, inView } = useInView({
    threshold: isMobile ? 0 : 1,
  });

  const enlargeConfig = isMobile ? [95, 100] : [88, 100];
  const shrinkConfig = isMobile ? [5, 0] : [12, 0];

  const { scrollYProgress } = useViewportScroll();
  const clipPathEnlarge = useTransform(
    scrollYProgress,
    [0, 0.1],
    enlargeConfig
  );
  const clipPathShrink = useTransform(scrollYProgress, [0, 0.1], shrinkConfig);
  const [completed, setCompleted] = useState(null);
  const [transform, setTransform] = useState(null);

  useEffect(
    () =>
      clipPathEnlarge.onChange((v) => {
        v === 70 ? setCompleted(true) : setCompleted(false);
        setTransform(v);
      }),
    [clipPathEnlarge]
  );
  useEffect(
    () =>
      clipPathShrink.onChange((v) => {
        v === 0 ? setCompleted(true) : setCompleted(false);
      }),
    [clipPathShrink]
  );

  return (
    <VideoContainer
      isCompleted={completed}
      ref={ref}
      style={{
        clipPath: `polygon(${clipPathShrink.get()}% 0, ${clipPathEnlarge.get()}% 0, ${clipPathEnlarge.get()}% 100%, ${clipPathShrink.get()}% 100%)`,
      }}
    >
      {children}
    </VideoContainer>
  );
};

export default Container;
