import React, { useState, useContext } from 'react';

import Container from 'components/Container';
import SectionTitle from 'components/UI/SectionTitle';
import LocaleContext from 'context/LocaleProvider';
import PostCard from './components/PostCard';

import {
  PostsGridHeader,
  FilterContainer,
  FilterTitle,
  FilterBlock,
  FilterText,
  PostsGridBlock,
  ButtonWrapper,
  ButtonOutline,
} from './styles';

const PostsGridModule = ({ component, articles }) => {
  const { filter, title } = component;
  const { selectedLocale } = useContext(LocaleContext);
  const [activeFilter, setActiveFilter] = useState('');
  const [postsCount, setPostsCount] = useState(9);

  const loadMore = () => setPostsCount((prew) => prew + 3);

  const tags = [
    ...new Set(
      articles
        ?.map((article) => {
          return article?.node?.tags;
        })
        ?.flat()
    ),
  ];

  return (
    <Container
      cap="1256"
      topSpacingDesktop="196"
      bottomSpacingDesktop="75"
      topSpacingMobile="110"
      bottomSpacingMobile="60"
    >
      <PostsGridHeader>
        {title && <SectionTitle text={title} displaced />}
        <FilterContainer>
          {filter && <FilterTitle elementTheme="h4">{filter}</FilterTitle>}
          {tags?.length && (
            <FilterBlock>
              {tags?.map((filter, i) => (
                <FilterText
                  elementTheme="caption"
                  key={`${filter}_${i}`}
                  active={filter === activeFilter}
                  onClick={() =>
                    filter === activeFilter
                      ? setActiveFilter('')
                      : setActiveFilter(filter)
                  }
                >
                  {i === tags?.length - 1 ? filter : `${filter},`}
                </FilterText>
              ))}
            </FilterBlock>
          )}
        </FilterContainer>
      </PostsGridHeader>
      <PostsGridBlock>
        {articles
          ?.filter(({ node }) =>
            activeFilter ? node?.tags?.includes(activeFilter) : node?.tags
          )
          ?.map(({ node }, index) => {
            return (
              index < postsCount && (
                <PostCard
                  title={node.title}
                  excerpt={node.excerpt}
                  image={node.thumbnailImage}
                  link={node?.page}
                  key={node.contentful_id}
                />
              )
            );
          })}
      </PostsGridBlock>
      {articles?.length > postsCount && (
        <ButtonWrapper>
          <ButtonOutline onClick={loadMore}>
            {selectedLocale?.includes('en')
              ? 'LOAD MORE ARTICLES'
              : "VOIR PLUS D'ARTICLES"}
          </ButtonOutline>
        </ButtonWrapper>
      )}
    </Container>
  );
};

export default PostsGridModule;
