import styled from 'styled-components';

export const ContainerIframe = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  pointer-events: none;

  @media (max-width: ${(props) => props.theme.breakpoints.xl}) {
    height: 100%;
    padding-top: 0;
  }
  .responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100% !important;
    height: 100% !important;
  }
`;

export const WrapperIframe = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;
