import styled from 'styled-components';

export const ContactHeroWrapper = styled.div`
  position: relative;
`;

export const ContentContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const InfoBlock = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ContactsContainer = styled.div`
  display: flex;
  align-items: flex-end;

  @media (max-width: ${(props) => props.theme.breakpoints.l}) {
    flex-direction: column;
    align-items: flex-start;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    margin-bottom: 37px;
    margin-top: calc(100vh - 110px - 160px);
  }
`;

export const ContactBlock = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 260px;

  & > p {
    color: ${(props) => props.theme.colors.light};
    font-size: ${(props) => props.theme.fonts.body} !important;

    & > b {
      color: ${(props) => props.theme.colors.secondary};
    }

    & > a {
      color: ${(props) => props.theme.colors.light};
      font-weight: 400;
    }

    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
      font-size: ${(props) => props.theme.fonts.body_md} !important;
    }

    @media (max-width: ${(props) => props.theme.breakpoints.md}) {
      max-width: 205px;
    }

    @media (min-width: ${(props) => props.theme.breakpoints.l}) {
      font-size: ${(props) => props.theme.fonts.body_l} !important;
    }
  }

  &:not(&:first-child) {
    margin-left: 40px;
    @media (max-width: ${(props) => props.theme.breakpoints.l}) {
      margin-left: 0;
    }
    @media (max-width: ${(props) => props.theme.breakpoints.md}) {
      display: none;
    }
  }
`;

export const LinksBlock = styled.div`
  display: flex;
  margin-bottom: 25px;
  @media (max-width: ${(props) => props.theme.breakpoints.l}) {
    margin-top: 25px;
  }
`;
