import React, { useState } from 'react';
import GoogleMapReact from 'google-map-react';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { MapWrapper, GradientBlock } from './styles';
import MapPoint from './MapPoint';

const BackgroundMap = ({ address }) => {
  const isMobile = useMediaQuery('(max-width:960px)');
  const [mapLoaded, setMapLoaded] = useState(false);

  const coord = {
    center: {
      lat: address.lat,
      lng: isMobile ? address.lon : address.lon + 0.013,
    },
    zoom: 15.5,
  };

  const createMapOptions = (maps) => {
    return {
      draggable: false,
      zoomControl: false,
      scrollwheel: false,
      disableDoubleClickZoom: true,
      disableDefaultUI: true,
      panControl: false,
      mapTypeControl: false,
      scrollwheel: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      draggableCursor: 'default',
      styles: [
        {
          featureType: 'all',
          elementType: 'labels.text.fill',
          stylers: [
            {
              saturation: 36,
            },
            {
              color: '#000000',
            },
            {
              lightness: 40,
            },
          ],
        },
        {
          featureType: 'all',
          elementType: 'labels.text.stroke',
          stylers: [
            {
              visibility: 'on',
            },
            {
              color: '#000000',
            },
            {
              lightness: 16,
            },
          ],
        },
        {
          featureType: 'all',
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'administrative',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#000000',
            },
            {
              lightness: 20,
            },
          ],
        },
        {
          featureType: 'administrative',
          elementType: 'geometry.stroke',
          stylers: [
            {
              color: '#000000',
            },
            {
              lightness: 17,
            },
            {
              weight: 1.2,
            },
          ],
        },
        {
          featureType: 'administrative',
          elementType: 'labels',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'administrative.country',
          elementType: 'all',
          stylers: [
            {
              visibility: 'simplified',
            },
          ],
        },
        {
          featureType: 'administrative.country',
          elementType: 'geometry',
          stylers: [
            {
              visibility: 'simplified',
            },
          ],
        },
        {
          featureType: 'administrative.country',
          elementType: 'labels.text',
          stylers: [
            {
              visibility: 'simplified',
            },
          ],
        },
        {
          featureType: 'administrative.province',
          elementType: 'all',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'administrative.locality',
          elementType: 'all',
          stylers: [
            {
              visibility: 'simplified',
            },
            {
              saturation: '-100',
            },
            {
              lightness: '30',
            },
          ],
        },
        {
          featureType: 'administrative.neighborhood',
          elementType: 'all',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'administrative.land_parcel',
          elementType: 'all',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'landscape',
          elementType: 'all',
          stylers: [
            {
              visibility: 'simplified',
            },
            {
              gamma: '0.00',
            },
            {
              lightness: '74',
            },
          ],
        },
        {
          featureType: 'landscape',
          elementType: 'geometry',
          stylers: [
            {
              color: '#000000',
            },
            {
              lightness: 20,
            },
          ],
        },
        {
          featureType: 'landscape.man_made',
          elementType: 'all',
          stylers: [
            {
              lightness: '3',
            },
          ],
        },
        {
          featureType: 'poi',
          elementType: 'all',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'poi',
          elementType: 'geometry',
          stylers: [
            {
              color: '#000000',
            },
            {
              lightness: 21,
            },
          ],
        },
        {
          featureType: 'road',
          elementType: 'geometry',
          stylers: [
            {
              visibility: 'simplified',
            },
          ],
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#000000',
            },
            {
              lightness: 17,
            },
          ],
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.stroke',
          stylers: [
            {
              color: '#000000',
            },
            {
              lightness: 29,
            },
            {
              weight: 0.2,
            },
          ],
        },
        {
          featureType: 'road.arterial',
          elementType: 'geometry',
          stylers: [
            {
              color: '#000000',
            },
            {
              lightness: 18,
            },
          ],
        },
        {
          featureType: 'road.local',
          elementType: 'geometry',
          stylers: [
            {
              color: '#000000',
            },
            {
              lightness: 16,
            },
          ],
        },
        {
          featureType: 'transit',
          elementType: 'geometry',
          stylers: [
            {
              color: '#000000',
            },
            {
              lightness: 19,
            },
          ],
        },
        {
          featureType: 'water',
          elementType: 'geometry',
          stylers: [
            {
              color: '#000000',
            },
            {
              lightness: 17,
            },
          ],
        },
      ],
    };
  };

  return (
    <MapWrapper mapLoaded={mapLoaded}>
      <GradientBlock mapLoaded={mapLoaded} />
      {address && (
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.GOOGLE_API_KEY }}
          defaultCenter={coord.center}
          defaultZoom={coord.zoom}
          options={createMapOptions}
          onTilesLoaded={() => setMapLoaded(true)}
        >
          <MapPoint lat={address.lat} lng={address.lon} />
        </GoogleMapReact>
      )}
    </MapWrapper>
  );
};

export default BackgroundMap;
