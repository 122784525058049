import React from 'react';
import getRichText from '../../utils/functions/getRichText';
import { GenericBody, GenericContainer, GenericTitle } from './styles';
import Container from 'components/Container';

const Generic = (props) => {
  const { title, body } = props;
  const content = getRichText(body);

  return (
    <GenericContainer>
      <Container>
        {title && <GenericTitle as="h1">{title}</GenericTitle>}
        <GenericBody>{content}</GenericBody>
      </Container>
    </GenericContainer>
  );
};

export default Generic;
