import styled from 'styled-components';
import Typography from 'components/UI/Typography';
import { h2 } from 'components/UI/Typography/styles';

export const GenericTitle = styled(Typography)`
  margin: 120px 0 130px;
  margin-right: auto;
  ${h2};
  font-weight: 700;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    margin: -20px 0 50px;
    margin-right: auto;
  }
`;

export const GenericContainer = styled.div`
  margin-bottom: 50px;
  margin-top: 93px;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    margin-top: 88px;
    margin-bottom: 0;
  }
`;

export const GenericBody = styled.div`
  max-width: 600px;
  padding: 0 30px;
  margin: 0 auto;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    padding: 0px;
  }

  p,
  ul,
  ol {
    margin-bottom: 30px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 25px 0;
    font-weight: bold;
  }

  a {
    font-size: 18px;
    text-decoration: underline;
    font-weight: 400;

    &:hover {
      color: ${(props) => props.theme.colors.secondary};
    }
  }

  ol,
  ul {
    > li {
      p {
        display: initial;
      }

      > ul,
      > ol {
        display: block;

        > li {
          display: list-item;
        }
      }
    }
  }

  li {
    margin-bottom: 0;

    :before {
      font-size: 18px;
    }

    * {
      margin: 0 !important;
      display: inline;
    }
    p {
      display: block;
    }
  }
`;
