import React from 'react';
import PropTypes from 'prop-types';
import { useWindowSize } from 'react-use';
import MediaAsset from 'components/UI/MediaAsset';
import SectionTitle from 'components/UI/SectionTitle';
import Container from 'components/Container';
import Accordion from './components/Accordion';

import theme from 'utils/styles/theme.js';
import {
  AccordionWrapper,
  InformationContainer,
  AccordionContainer,
  ImageContainer,
  Accordions,
} from './styles';

const AccordionModule = ({ component }) => {
  const {
    title,
    image,
    secondaryTitle,
    tertiaryTitle,
    listItems,
    tertiaryTitleListItems,
  } = component;

  const { width } = useWindowSize();

  return (
    <AccordionWrapper>
      {image && width < 960 && (
        <ImageContainer>{<MediaAsset image={image} />}</ImageContainer>
      )}
      <Container
        style={{ position: 'initial' }}
        containerBackgroundColor={theme.colors.primary}
        topSpacingMobile="45"
        bottomSpacingMobile="45"
        topSpacingDesktop="52"
        bottomSpacingDesktop="149"
        cap="1256"
      >
        <InformationContainer>
          {title && (
            <SectionTitle
              lightdarkMode={true}
              text={title}
              displaced={true}
              maxWidth={width >= 960 ? 880 : 300}
            />
          )}
        </InformationContainer>
        <AccordionContainer>
          <Accordions>
            {listItems && (
              <Accordion
                title={secondaryTitle}
                items={listItems}
                defActive={true}
              />
            )}
            {tertiaryTitleListItems && (
              <Accordion
                title={tertiaryTitle}
                items={tertiaryTitleListItems}
                defActive={false}
              />
            )}
          </Accordions>
          {image && width >= 960 && (
            <ImageContainer>
              {<MediaAsset animated image={image} />}
            </ImageContainer>
          )}
        </AccordionContainer>
      </Container>
    </AccordionWrapper>
  );
};

AccordionModule.propTypes = {
  component: PropTypes.object.isRequired,
};

AccordionModule.defaultProps = {
  component: {},
};

export default AccordionModule;
