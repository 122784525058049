import React from 'react';

import Container from 'components/Container';
import { StatistickBlock, Title, StatistickContainer } from './styles';
import theme from '../../utils/styles/theme';
import getRichText from '../../utils/functions/getRichText';

const StatisticsModule = ({ component }) => {
  const { statisticBlocks } = component;

  return (
    <Container
      containerBackgroundColor={theme.colors.primary}
      topSpacingDesktop="44"
      bottomSpacingDesktop="4"
      topSpacingMobile="63"
      bottomSpacingMobile="23"
    >
      <StatistickContainer>
        {statisticBlocks.map((statistic, i) => (
          <StatistickBlock key={`${statistic}_${i}`}>
            <Title elementTheme="h3">{statistic.title}</Title>
            {getRichText(statistic.body)}
          </StatistickBlock>
        ))}
      </StatistickContainer>
    </Container>
  );
};

export default StatisticsModule;
