import React, { useEffect, useState, useContext } from 'react';
import { gsap, Power1 } from 'gsap';
import { SplitText } from '@cyriacbr/react-split-text';
import { useInView } from 'react-intersection-observer';
import LoaderContext from 'context/LoaderProvider';

const initAnimation = (ref) => {
  const lines = ref.querySelectorAll('#line');

  gsap
    .timeline()
    .fromTo(
      lines,
      {
        autoAlpha: 0,
      },
      {
        autoAlpha: 1,
        stagger: 0.07,
        duration: 0.5,
        ease: Power1.easeOut,
      },
      0
    )
    .fromTo(
      lines,
      {
        y: '50%',
      },
      {
        y: 0,
        stagger: 0.06,
        duration: 0.6,
        ease: Power1.easeOut,
      },
      0
    );
};

const AnimatedSplitBody = ({ children, isAwaitingLoading }) => {
  const { hasLoaded } = useContext(LoaderContext);
  const { ref, inView } = useInView({
    threshold: 0,
  });

  const [splitTextRef, setSplitTextRef] = useState(null);

  useEffect(() => {
    if (inView && splitTextRef && isAwaitingLoading && hasLoaded) {
      console.log('here2')
      initAnimation(splitTextRef)
    } else if (!isAwaitingLoading && inView && splitTextRef) {
      console.log('here')
      initAnimation(splitTextRef)
    }
  }, [inView, splitTextRef, hasLoaded]);

  return (
    <div style={{ opacity: inView ? 1 : 0 }} ref={ref}>
      <SplitText
        ref={(textRef) => setSplitTextRef(textRef)}
        LineWrapper={({ children }) => (
          <span id="line" style={{ display: 'block' }}>
            {children}
          </span>
        )}
      >
        {children}
      </SplitText>
    </div>
  );
};

export default AnimatedSplitBody;
