import styled from 'styled-components';
import Typography from 'components/UI/Typography';
import { p } from 'components/UI/Typography/styles';

export const AccordionWrapper = styled.div``;

export const AccordionButtonTypography = styled(Typography)`
  margin-bottom: 22px;
  font-family: ${(props) => props.theme.fontFamily.recursive};
  font-size: ${(props) => props.theme.fonts.h3};
  line-height: 1.4;
  color: ${(props) => props.theme.colors.white};
  cursor: ${(props) => !props.isActive && 'pointer'};

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    margin-bottom: ${(props) => (props.isActive ? '24px' : '30px')};
    font-size: ${(props) => props.theme.fonts.h3_md};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    font-size: ${(props) => props.theme.fonts.h3_l};
  }

  span {
    pointer-events: none;
  }
`;

export const AccordionButtonTitle = styled.span``;

export const AccordionListWrapper = styled.div`
  margin-bottom: ${(props) => props.isShown && '22px'};
  height: ${(props) => props.height + 'px'};
  overflow: hidden;
  transition: height 0.5s ease;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    margin-bottom: ${(props) => props.isShown && '30px'};
  }
`;

export const AccordionList = styled.ul`
  list-style-type: none;
  color: ${(props) => props.theme.colors.light};
`;

export const AccordionListItem = styled.li`
  line-height: 1.4;
  margin: 0;
  padding-left: 30px;
  font-size: ${(props) => props.theme.fonts.body};

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    font-size: ${(props) => props.theme.fonts.body_md};
  }
`;
