import React, { useEffect } from 'react';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import {
  StandardImage,
  MediaAssetContainer,
  AnimatedImageContainer,
  Overlay,
} from './styles';
import { useInView } from 'react-intersection-observer';
import { LazyMotion, useAnimation, domAnimation } from 'framer-motion';

const overlayVariants = {
  visible: {
    scaleY: 0,
    transition: {
      type: 'spring',
      damping: 30,
      mass: 0.75,
      stiffness: 100,
    },
  },
  hidden: {
    scaleY: 1,
    transition: {
      type: 'spring',
      damping: 30,
      mass: 0.75,
      stiffness: 100,
    },
  },
};

const imageVariants = {
  visible: {
    scale: 1,
    transition: {
      type: 'spring',
      damping: 30,
      mass: 0.75,
      stiffness: 100,
    },
  },
  hidden: {
    scale: 1.3,
    transition: {
      type: 'spring',
      damping: 30,
      mass: 0.75,
      stiffness: 100,
    },
  },
};

const MediaAsset = (props) => {
  const {
    image,
    animated,
    condition,
    isOverwritten,
    isLight = false,
    slideUp = true,
  } = props;
  const [ref, inView] = useInView({
    threshold: 0.5,
  });
  const controls = useAnimation();

  useEffect(() => {
    if (condition && isOverwritten) {
      controls.start('visible');
    } else if (isOverwritten) {
      controls.start('hidden');
    }
  }, [condition]);

  useEffect(() => {
    if (inView && !isOverwritten) {
      controls.start('visible');
    } else if (!isOverwritten) {
      controls.start('hidden');
    }
  }, [inView]);

  if (image?.file?.url) {
    return <StandardImage src={image.file.url} alt={image?.title} />;
  }

  const img = image?.gatsbyImageData
    ? getImage(image?.gatsbyImageData)
    : image?.file?.url;
  const alt = image?.title;

  return typeof img !== 'object' &&
    (img?.includes('.svg') || img?.includes('.gif')) &&
    !animated ? (
    <MediaAssetContainer className="media-asset">
      <StandardImage src={img} alt={alt} />
    </MediaAssetContainer>
  ) : img && !animated ? (
    <MediaAssetContainer className="media-asset">
      <GatsbyImage alt={alt} image={img} />
    </MediaAssetContainer>
  ) : animated ? (
    <MediaAssetContainer ref={ref} className="media-asset">
      <LazyMotion features={domAnimation}>
        {slideUp && (
          <Overlay
            isLight={isLight}
            animate={controls}
            initial="hidden"
            variants={overlayVariants}
          ></Overlay>
        )}
        <AnimatedImageContainer
          animate={controls}
          initial="hidden"
          variants={imageVariants}
          className="AnimatedImageContainer"
        >
          <GatsbyImage alt={alt} image={img} />
        </AnimatedImageContainer>
      </LazyMotion>
    </MediaAssetContainer>
  ) : (
    <></>
  );
};

export default MediaAsset;
