import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useWindowSize } from 'react-use';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import { useDeviceDetect } from '../../../hooks/useDeviceDetect';
import AnodizingTooltip from './AnodizingTooltip';
import AnodizingBody from './AnodizingBody';
import Image from './Image';
import AnodizingDecorationIcon from './AnodizingDecorationIcon';

import {
  Anodizing,
  AnodizingList,
  SliderWrapper,
  StyledSlider,
  TitleCount,
  TitleText,
  TitleTypography,
} from './styles';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const List = ({ list }) => {
  const tooltipRef = useRef();

  const [image, setImage] = useState(0);
  const [active, setActive] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);

  const { isMobile } = useDeviceDetect();
  const { width } = useWindowSize();

  const sliderSettings = {
    infinite: false,
    speed: 500,
    variableWidth: true,
    swipeToSlide: true,
    dots: false,
    arrows: false,
    beforeChange: (current, next) => {
      setImage(next);
    },
  };

  const ToggleTooltip = (current) => {
    setActive(current);
    setImage(current);
    setActiveIndex(current);
  };

  const mouseEnter = (current) => {
    setActive(current);
    setImage(current);
    setActiveIndex(current);
  };

  const mouseLeave = () => {
    setActive(null);
  };

  useOutsideClick(tooltipRef, () => {
    if (active !== null) setActive(null);
  });

  return (
    <>
      <AnodizingDecorationIcon activeIndex={activeIndex} />
      {list[image].image && <Image active={active} image={list[image].image} />}

      {width >= 960 ? (
        <AnodizingList ref={tooltipRef}>
          {list &&
            list.map((anodizing, inx) => (
              <Anodizing
                key={anodizing?.contentful_id}
                onClick={() => isMobile && ToggleTooltip(inx)}
                onMouseEnter={() => !isMobile && mouseEnter(inx)}
                onMouseLeave={() => !isMobile && mouseLeave()}
                className={active === inx ? 'is-active' : ''}
              >
                {anodizing?.title && (
                  <TitleTypography
                    elementTheme="h3 semi-bold"
                    active={active === inx}
                  >
                    <TitleCount>{inx + 1}</TitleCount>
                    <TitleText>{anodizing?.title}</TitleText>
                  </TitleTypography>
                )}
                {anodizing?.body && (
                  <AnodizingTooltip
                    popupBody={anodizing?.body}
                    isShown={active === inx}
                  />
                )}
              </Anodizing>
            ))}
        </AnodizingList>
      ) : (
        <SliderWrapper>
          <StyledSlider {...sliderSettings}>
            {list &&
              list.map((anodizing, inx) => (
                <Anodizing key={anodizing?.contentful_id}>
                  {anodizing?.title && (
                    <TitleTypography elementTheme="h3 semi-bold">
                      <TitleCount>{inx + 1}</TitleCount>
                      <TitleText>{anodizing?.title}</TitleText>
                    </TitleTypography>
                  )}
                  {anodizing?.body && <AnodizingBody body={anodizing?.body} />}
                </Anodizing>
              ))}
          </StyledSlider>
        </SliderWrapper>
      )}
    </>
  );
};

List.prototype = {
  list: PropTypes.array.isRequired,
};

List.defaultProps = {
  list: [],
};

export default List;
